import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import FieldHandler from '../customfields/FieldHandler';
import UpdateCustomFormTemplate from './UpdateCustomFormTemplate';


const styles = {
    card_details: { paddingTop: "5%", height: "100%", width: "100%", backgroundSize: "cover", fontFamily: "serif", borderBottomStyle: "dashed", borderBottomWidth: 1, display: "flex", justifyContent: "space-evenly" },
}


function SampleCustomForm(props) {


    const [allFieldDetails, setAllFieldDetails] = useState(props.allFieldDetails)
    const [formEditStatus, setFormEditStatus] = useState(false)
    const [showFormEditModal, setShowFormEditModal] = useState(false)

    useEffect(() => {
        setAllFieldDetails(props.allFieldDetails)
    }, [props.allFieldDetails])

    return (
        <Row style={{ height: "100%" }}>
            <Col md="1" onClick={() => props.setCurrentEditField(null)}></Col>
            {props.showForm &&
                <Col className='my-scrollbar' md="10" style={{ height: "100%", overflowY: "scroll", }}
                    onDragOver={(event) => props.allowDrop(event)}
                    onDrop={(e) => { allFieldDetails.length == 0 && props.drop(e); }}
                >
                    <Card onMouseEnter={() => setFormEditStatus(true)} onMouseLeave={() => setFormEditStatus(false)} style={{ backgroundColor: "#fff", marginTop: "5%", borderRadius: 5 }}>


                        <div style={{ ...styles.card_details, backgroundImage: `url(${props.formDetails.image_header})`, objectFit: "cover" }} title='Form Details'>
                            <div style={{ width: "85%" }}>
                                <h4 style={{ textAlign: "left", fontWeight: "bolder" }}>
                                    {props.formDetails.label && props.formDetails.label.toUpperCase()}
                                </h4>
                                <p style={{ textAlign: "left" }}>{props.formDetails.description && props.formDetails.description}</p>
                            </div>
                            {
                                formEditStatus ?
                                    <div style={{ display: "flex", width: "10%", position: "absolute", right: -10 }}>
                                        <div className='card-custom-icons' onClick={() => { setShowFormEditModal(true); }}
                                            style={{ backgroundColor: "blue", padding: 2 }}  >
                                            <i className="fa fa-pencil" style={{ fontSize: 19, color: "#fff" }} aria-hidden="true">
                                            </i>
                                        </div>
                                    </div> : <></>
                            }
                        </div>
                        {allFieldDetails.length == 0 ?

                            <div style={{ paddingBlock: "5%" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderStyle: "dashed", borderRadius: 15, marginInline: "5%", paddingBlock: 30 }}>
                                    Drag Your Form Fields Here
                                </div>
                            </div>
                            :
                            allFieldDetails.length > 0 &&
                            <Row style={{ display: "flex", width: "100%", flexWrap: "wrap", paddingInline: "5%", paddingBottom: "5%", paddingTop: "3%" }}>
                                {
                                    allFieldDetails.map((item, index) => {
                                        console.log(item,'checkfield value')
                                        if (item.type !== 'OCR_VIEW') {
                                            return (
                                                <Col md={item.style == '' ? 12 : parseInt(item.style)}
                                                    onDoubleClick={() => props.editElement(item.type)}
                                                    onDrop={(e) => props.drop(e, item.sequence)}
                                                    style={{ cursor: "grab" }}
                                                    key={index}>
                                                    {
                                                        props.currentEditField == index ?
                                                            <div style={{ position: "relative", float: "right", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>

                                                                <div onClick={() => props.editElement(item.type)} style={{ padding: 5, backgroundColor: "blue", marginLeft: 5, marginBottom: 10, borderRadius: 5 }}> <i className="fa fa-pencil" style={{ fontSize: 20, color: "#fff" }} aria-hidden="true"></i></div>

                                                                <div onClick={() => props.removeElement(item.id)} style={{ padding: 5, backgroundColor: "red", marginLeft: 5, borderRadius: 5 }}><i className="fa fa-trash" style={{ fontSize: 20, color: "#fff" }} aria-hidden="true"></i></div>
                                                            </div> : <></>
                                                    }

                                                    <div
                                                        onDragStart={(e) => { props.dragStart(e, '', index); props.setCurrentDraggingElement(item) }}
                                                        onDragEnter={(e) => props.dragEnter(e, index)}
                                                        onDragOver={(event) => props.allowDrop(event)}
                                                        // onDrop={(e) => props.drop(e, index)}
                                                        draggable
                                                        style={{
                                                            textAlign: 'center', display: "flex",
                                                            justifyContent: 'center',
                                                            borderWidth: props.currentEditField == index ? 1 : 0, borderStyle: "solid", borderColor: "black", padding: 10, paddingLeft: 20
                                                        }}
                                                        onClick={() => { props.setCurrentEditField(index); }}
                                                    >

                                                        <FieldHandler data={item} type={item.type} editable={true} />

                                                    </div>
                                                </Col>
                                            )
                                        }
                                    })}
                            </Row>
                        }

                    </Card>
                    <UpdateCustomFormTemplate showModal={showFormEditModal} handleClose={() => { setShowFormEditModal(false) }} selectedData={props.formDetails} />
                </Col>
            }
            <Col onClick={() => props.setCurrentEditField(null)} >
            </Col>
        </Row>
    )
}

export default SampleCustomForm
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { redirect } from '../../../lib/utils'
import "./notification.css"
export default class Sucessful extends Component {
  render() {
    return (
      <div style={{justifyContent:"center",alignItems:"center",display:"flex",textAlign:"center",height:window.innerHeight, backgroundImage: 'linear-gradient(45deg, #019871, #a0ebcf)'}}>
            <div class="wrapperAlert">

<div class="contentAlert">

  <div class="topHalf">

    <center><p><svg viewBox="0 0 512 512" width="100" title="check-circle">
      <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
      </svg></p></center>
    <center><h1>Congratulations</h1></center>
    <center>"Thank you for your payment. It has been received and your subscription has been successfully activated. We appreciate your business and look forward to continuing to serve you."</center>
    <br/>
    <cenetr><Button variant='success' style={{zIndex:1}} onClick={()=>{ redirect('/dashboard')}}>Back To Home</Button></cenetr>

   <ul class="bg-bubbles">
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
     <li className='jeev'></li>
   </ul>
  </div>
</div>        

</div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { fileUpload, fileUpload_without_token, ocrView } from '../../action';
import FileTypeFieldHandler from './FileTypeFieldHandler';
import { getHiddenDeleteField } from '../../../../lib/utils';

class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileData: {}
        }
    }

    fileUpload(data) {
        if (data.files[0]) {

            if (this.props.token !== undefined) {
                if (this.props.fieldData.ocr_view == true) {
                    this.props.ocr(this.props.token.access, data.files[0], (text) => { this.props.onChange(text.id); this.props.onOCR_View(getHiddenDeleteField(this.props.fieldData.id, this.props.fields).id, text.text) })
                }
                else (
                    this.props.file_Upload(this.props.token.access, data.files[0], (data) => this.props.onChange(data.id))
                )
            }
            else {
                this.props.fileUpload_without_token(this.props.ShareToken, data.files[0], (data) => this.props.onChange(data.data.id))
            }
        }
        else {
            alert("Your file is not been selected yet");
        }
    }

    render() {
        return (
            <div>
                <Form.Control accept={this.props.accept} title={this.props.title} type="file" onChange={(e) => { this.fileUpload(e.target); }} />
                <FileTypeFieldHandler id={this.props.value} sharedToken={this.props.ShareToken} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        fields: state.formsReducer.fields,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        file_Upload: (accessToken, file, returnedData) => dispatch(fileUpload(accessToken, file, returnedData)),
        fileUpload_without_token: (shared_token, file, returnedData) => dispatch(fileUpload_without_token(shared_token, file, returnedData)),
        ocrView: (accessToken, file, returnedText) => dispatch(ocrView(accessToken, file, returnedText))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, Stack } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import FromsUpdateModal from './FromsUpdateModal';
import { fetchFormsList, createForms, deleteForm } from '../action';
import { fetchProjectForms } from '../../project/action';
import { formateDate } from '../../../lib/utils';
import DeleteModal from '../../common/components/DeleteModal';
import { setNotification } from '../../common/action';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import { Link } from 'react-router-dom';

class Forms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUpdateModal: false,
            showDeleteModal: false,
            selectedData: {},
            label: '',
            description: '',
            show: false,
            search: '',
            headerImage: "",
            footerImage: "",
            fileInputKey: Date.now(),
            email_WorkFlow: false,
            email_Comment: false,
            email_DataEntry: false,
            timer: false,
            timer_sec: 0
        }

    }

    deleteAction(event) {
        this.props.deleteForm(this.props.token.access, event.id, event.type);
    }

    componentDidMount() {
        if (this.props.params === undefined) {
            this.props.getFormList(this.props.token.access);
        }
        else {
            this.props.params.id && this.props.getProjectForms(this.props.token.access, this.props.params.id);
        }
    }

    handleSearchAction() {
        this.props.getFormList(this.props.token.access, `&search=${this.state.search}`);
    }

    handleCreateAction = (event) => {
        event.preventDefault();
        let requestBody = {
            "label": this.state.label,
            "description": this.state.description,
            "image_header": this.state.headerImage,
            "image_footer": this.state.footerImage,
            "email_formdatas": this.state.email_DataEntry,
            "email_workflows": this.state.email_WorkFlow,
            "email_comments": this.state.email_Comment,
            "timmer": this.state.timer_sec,
            "type": "N",
            "custom_template_type": "S"
        }

        if (this.props.params && this.props.params.id) {
            requestBody["project"] = this.props.params.id;
        }
        if (requestBody["label"].replace(/\s/g, '').length) {
            this.props.addForms(this.props.token.access, requestBody, () => { });
            this.setState({
                label: '',
                description: '',
                headerImage: null,
                footerImage: null,
                fileInputKey: Date.now(),
                timer_sec: 0
            })
        }
        else {
            this.props.setNotification(true, "Please Provide Proper Name And Description.", "danger")
        }
        this.setState({
            label: '',
            description: '',
            headerImage: "",
            footerImage: "",
        })
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div >
                        <Breadcrumbs name={this.props} />
                    </div>
                    <Link to="/customforms">
                        <Button variant='warning'>Go To Custom Forms</Button>
                    </Link>
                </div>

                <Row>

                    <Col>
                        <p>Forms</p>
                        <Card>
                            <Card.Body className='font-medium'>
                                <div >
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Form Name <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Template Name" required value={this.state.label}
                                                onChange={event => { this.setState({ label: event.target.value }); }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Description" value={this.state.description}
                                                onChange={event => { this.setState({ description: event.target.value }); }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Header Banner</Form.Label>
                                            <Form.Control size='sm' type="file" key={this.state.fileInputKey}
                                                onChange={event => { this.setState({ headerImage: event.target.files[0] }) }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Footer Banner</Form.Label>
                                            <Form.Control size='sm' type="file" key={this.state.fileInputKey}
                                                onChange={event => { this.setState({ footerImage: event.target.files[0] }) }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Email Sent</Form.Label>
                                            <Form.Check type="switch" label="In Workflow?" checked={this.state.email_WorkFlow}
                                                onChange={() => this.setState({ email_WorkFlow: !this.state.email_WorkFlow })} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Email Sent</Form.Label>
                                            <Form.Check type="switch" label="In Comment?" checked={this.state.email_Comment}
                                                onChange={() => this.setState({ email_Comment: !this.state.email_Comment })} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Email Sent</Form.Label>
                                            <Form.Check type="switch" label="Form Data Entry?" checked={this.state.email_DataEntry}
                                                onChange={() => this.setState({ email_DataEntry: !this.state.email_DataEntry })} />
                                        </Col>
                                        {this.state.timer !== true ?
                                            <Col md={3}>
                                                <Form.Label>Timer</Form.Label>
                                                <Form.Check type="switch" label="Add Timer?" checked={this.state.timer}
                                                    onChange={() => this.setState({ timer: !this.state.timer })} />
                                            </Col> : <Col md={3}>
                                                <Form.Label>Timer</Form.Label>
                                                <Form.Control size="sm" type="number" placeholder="Enter Time In Sec" required value={this.state.timer_sec}
                                                    onChange={event => { this.setState({ timer_sec: event.target.value }); }} />
                                            </Col>

                                        }



                                        <Col className='d-flex align-items-end'>
                                            <Button className='mt-3' variant="outline-success" size='md' onClick={(e) => { this.handleCreateAction(e) }}>CREATE</Button>
                                        </Col>
                                    </Row>
                                </div>
                                <Row className='mt-5 d-flex justify-content-end'>
                                    <Col md='3' >
                                        <Form.Control size="sm" type="text" placeholder="Search Template" required value={this.state.search} onChange={(event) => { this.setState({ search: event.target.value }); }} />
                                    </Col>
                                    <Col md='1' className='d-flex align-items-end'>
                                        <Button onClick={() => this.handleSearchAction()} variant="success" size='sm'>Search</Button>
                                    </Col>
                                </Row>
                                <div className='mt-3 font-medium'>
                                    {
                                        this.props.projectFormList.results.length > 0 ?
                                            <DataTable
                                                totalItemsCount={this.props.projectFormList && this.props.projectFormList.count > 0}
                                                headers={[`Template Name`, `Description`, `Created On`, `Last Modified`, `Action`]}
                                                onPageChange={(pageNumber) => this.props.getProjectForms(this.props.token.access, pageNumber)}
                                                body={this.props.projectFormList.results.map((row, index) => {
                                                    return [
                                                        row.label,
                                                        row.description,
                                                        formateDate(row.date_added),
                                                        formateDate(row.date_updated),
                                                        <Row className='actionWidth'>
                                                            <Stack direction="horizontal" gap={1}>
                                                                <Col sm='12'>
                                                                    <Button variant="outline-warning" size='sm' className="m-1"
                                                                        onClick={() => this.setState({ showUpdateModal: true, selectedData: row })} >EDIT</Button>
                                                                    <Link to={`/forms/${row.id}/fields`} >
                                                                        <Button variant="outline-secondary" size='sm' className="m-1">FIELDS</Button>
                                                                    </Link>
                                                                    <Link to={`/forms/${row.id}/view_data`} >
                                                                        <Button variant="outline-primary" size='sm' className="m-1">VIEW</Button>
                                                                    </Link>
                                                                    <Link to={`/forms/${row.id}/members`} >
                                                                        <Button variant="outline-primary" size='sm' className="m-1">MEMBERS</Button>
                                                                    </Link>
                                                                    <Button onClick={() => this.setState({ showDeleteModal: true, selectedData: row })} variant="outline-danger" size='sm' className="m-1">DELETE</Button>
                                                                </Col>
                                                            </Stack>
                                                        </Row>
                                                    ]
                                                })}
                                            />
                                            :
                                            this.props.formList.data && this.props.formList.data.length > 0 &&
                                            <DataTable
                                                totalItemsCount={this.props.formList.count}
                                                headers={[`Template Name`, `Description`, `Data Count`, `Fields`, `Created On`, `Last Modified`, `Action`]}
                                                onPageChange={(pageNumber) => this.props.getFormList(this.props.token.access, `&search=${this.state.search}`, pageNumber)}
                                                body={this.props.formList.data.map((row, index) => {
                                                    return [
                                                        row.type == "C" ? `${"© "}${row.label}` : row.label,
                                                        row.description,
                                                        row.data_count,
                                                        row.field_count,
                                                        formateDate(row.date_added),
                                                        formateDate(row.date_updated),
                                                        <Row>
                                                            <Col sm='12'>
                                                                {
                                                                    row.type == 'C' ?
                                                                        <Link to={`/customforms/${row.id}`} >
                                                                            <Button title='Edit' variant="info" size='sm' style={{margin:"1px",padding:'1px'}}><i class="fa fa-pencil-square-o" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                                        </Link>
                                                                        :
                                                                        <Button title='Edit' variant="info" size='sm' style={{margin:"1px",padding:'1px'}}
                                                                            onClick={() => this.setState({ showUpdateModal: true, selectedData: row })} ><i class="fa fa-pencil-square-o" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                                }
                                                                <Link to={row.type == "C" ? `/customforms/${row.id}` : `/forms/${row.id}/fields`} >
                                                                    <Button title='Field' variant="secondary" style={{margin:"1px",padding:'1px'}} size='sm'><i class="fa fa-tasks" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                                </Link>
                                                                <Link to={`/forms/${row.id}/view_data`} >
                                                                    <Button title='View' variant="primary" style={{margin:"1px",padding:'1px'}} size='sm'><i className="fa fa-eye" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}} /></Button>
                                                                </Link>
                                                                <Link to={`/forms/${row.id}/members`} >
                                                                    <Button title='Member' variant="warning" style={{margin:"1px",padding:'1px'}} size='sm' ><i class="fa fa-users" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                                </Link>
                                                                <Link to={`/forms/template/${row.id}/share`}>
                                                                    <Button title='Share' variant="success" style={{margin:"1px",padding:'1px'}} size='sm' ><i class="fa fa-share" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                                </Link>
                                                                <Button title='Delete' onClick={() => this.setState({ showDeleteModal: true, selectedData: row })} variant="danger" style={{margin:"1px",padding:'1px'}} size='sm' ><i class="fa fa-trash-o" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                            </Col>
                                                        </Row>
                                                    ]
                                                })}
                                            />
                                    }
                                </div>
                                <DeleteModal modalText={"Delete Form ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                                <FromsUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formList: state.formsReducer.formList,
        projectFormList: state.projectReducer.formList,
        token: state.usersReducer.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteForm: (accessToken, formId, type) => dispatch(deleteForm(accessToken, formId, type)),
        getFormList: (accessToken, searchQuery, pageNumber) => dispatch(fetchFormsList(accessToken, searchQuery, pageNumber)),
        addForms: (accessToken, requestBody, createdFun) => dispatch(createForms(accessToken, requestBody, createdFun)),
        getProjectForms: (accessToken, Id) => dispatch(fetchProjectForms(accessToken, Id)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Forms);

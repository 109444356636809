import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import FieldHandler from '../customfields/FieldHandler';
import UpdateCustomFormTemplate from '../common/UpdateCustomFormTemplate';
import { fetchActiveTemplateForm, fetchFormTemplateAllCategory, fetchFormTemplateByCategory, fetchTemplateAllFields } from '../../forms/action';
import { connect } from 'react-redux';
import { full_field_details, getFields, } from '../FieldTypes';
import SampleCustomForm from '../common/SampleCustomForm';

const styles = {
    card_details: {
        paddingTop: "5%",
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
        fontFamily: "serif",
        borderBottomStyle: "dashed",
        borderBottomWidth: 1,
        display: "flex",
        justifyContent: "space-evenly"
    },
};

function TemplateForm(props) {
    const [allFieldDetails, setAllFieldDetails] = useState(props.templateForm);
    const [formEditStatus, setFormEditStatus] = useState(false);
    const [showFormEditModal, setShowFormEditModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [formTemplate, setFormTemplate] = useState([]);
    const [category, setCategory] = useState('all');
    const [useTemp, setUseTemp] = useState(null);       // For template data
    const[tempAllField, settempAllField] = useState()
    
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [showForm, setShowForm] = useState(false);
    const [currentEditField, setCurrentEditField] = useState(null);
    const [formDetails, setFormDetails] = useState({});
    const [currentDraggingElement, setCurrentDraggingElement] = useState({});
    const [editModal, setEditModal] = useState(false);
    const [list, setList] = useState([]);
    const allowDrop = (event) => {
        event.preventDefault();
    }
    const drop = (event, position, card_no = 0) => {

        event.preventDefault();

        const data = event.dataTransfer.getData("data");

        if (data !== '') {
            var requestBody = {
                "validation": {
                    "type": null,
                    "expression": null,
                    "error_message": null
                },
                "label": full_field_details[data].label,
                "type": full_field_details[data].type,
                "mandatory": false,
                "sequence": allFieldDetails.length,
                "template": formDetails.id,
                "display_in_main_view": true,
                "placeholder": full_field_details[data].placeholder,
                "tooltip": "",
                "master_data_code": "string",
                "check_unique": false,
                "unique_type": 1,
                "auto_sum": false,
                "deafult_value": full_field_details[data].defaultValue,
                'table_data': '',
                "is_delete": false,
                "card_no": card_no
            }
            if (position >= 5 || data == 'table') {
                requestBody['display_in_main_view'] = false
            }
            props.addFields(props.token.access, formDetails.id, requestBody);
        }


        if (Object.keys(currentDraggingElement).length > 0 && position !== undefined) {
            var requestBody = { ...currentDraggingElement, sequence: position - 1, }
            onUpdate(requestBody);
        }

    };

    const removeElement = (fieldId) => {
        props.deleteField(props.token.access, fieldId, formDetails.id)
    }
    const onUpdate = (updatedData) => {
        var requestBody = {
            "validation": {
                "type": updatedData.validation.type,
                "expression": updatedData.validation.expression,
                "error_message": updatedData.validation.error_message
            },
            "label": updatedData.label,
            "type": updatedData.type,
            "mandatory": updatedData.mandatory,
            "sequence": updatedData.sequence,
            "template": updatedData.template,
            "display_in_main_view": updatedData.display_in_main_view,
            "placeholder": updatedData.placeholder,
            "tooltip": updatedData.tooltip,
            "master_data_code": updatedData.master_data_code,
            "check_unique": updatedData.check_unique,
            "unique_type": updatedData.unique_type,
            "auto_sum": updatedData.auto_sum,
            "deafult_value": updatedData.deafult_value,
            "api": updatedData.api,
            "values": updatedData.values,
            "style": updatedData.currentWidth,
            'table_data': updatedData.table_data,
            'ocr_view': updatedData.ocr_view,
            "is_delete": false,
            "card_no": updatedData.card_no
        }
        if (requestBody["label"].replace(/\s/g, '').length) {
            props.editFieldForms(props.token.access, updatedData.id, requestBody, updatedData.template, () => { if (updatedData.type == 'radio' || updatedData.type == 'checkbox') { window.location.reload() } });
            setEditModal(false);
            setCurrentDraggingElement({});
        }
        else {
            props.setNotification(true, "Please Provide Correct Information", "danger")
        }
    }
    const dragStart = (e, type, position = list.length) => {
        dragItem.current = position;
        if (type !== '') {
            e.dataTransfer.setData("data", type);
        }
    };
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };


useEffect(()=>{
    setShowForm(true)
},[tempAllField])

    const handleCategory = (e) => {
        const categoryValue = e.target.value;
        setCategory(categoryValue);
        if (categoryValue) {
            props.fetchFormTemplateByCategory(props.token.access, categoryValue, setFormTemplate);
        }
       
    };

    useEffect(() => {
        setAllFieldDetails(props.templateForm);
    }, [props.templateForm]);

    useEffect(() => {
        if (props.token?.access) {
            props.fetchFormTemplateAllCategory(props.token.access);
            props.fetchFormTemplateByCategory(props.token.access, category, setFormTemplate);
           
        }
    }, [props.token, category]);

    useEffect(() => {
        setSelectedCategory(props.allCategory?.data || []);
    }, [props.allCategory]);

    const activeTemplate = (formId) => {
        
        
        // Fetch the template data and set it in useTemp
        props.fetchActiveTemplateForm(props.token.access, formId, (templateData) => {
            setUseTemp(templateData);  // Set template data
           
        });
        
       
    };
   
    useEffect(()=>{
      
        settempAllField(props.templateAllField)
    },[props.token,useTemp])
    // Use the state values for rendering or other operations
    console.log(tempAllField, 'tempAllField');
    console.log(props.templateAllField,'templateAllField')
    return (
        <Row style={{ height: "100%" }}>
            <Col md="1" onClick={() => props.setCurrentEditField(null)}></Col>
            {props.showForm && (
                <Col className='my-scrollbar' md="10" style={{ height: "100%", overflowY: "scroll" }}
                    onDragOver={props.allowDrop}
                    onDrop={(e) => { if (allFieldDetails?.data?.length === 0) props.drop(e); }}
                >
                    <Card onMouseEnter={() => setFormEditStatus(true)} onMouseLeave={() => setFormEditStatus(false)} style={{ backgroundColor: "#fff", marginTop: "5%", borderRadius: 5 }}>
                        <div style={{ ...styles.card_details, backgroundImage: `url(${props.formDetails.image_header})` }} title='Form Details'>
                            <div style={{ width: "85%" }}>
                                <h4 style={{ textAlign: "left", fontWeight: "bolder" }}>Template Form</h4>
                            </div>
                        </div>
                        {allFieldDetails?.data?.length === 0 ? (
                            <div style={{ paddingBlock: "5%" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderStyle: "dashed", borderRadius: 15, marginInline: "5%", paddingBlock: 30 }}>
                                    Template Not Available
                                </div>
                            </div>
                        ) : 
                        useTemp?.custom_template_type == 'S'?
                        <SampleCustomForm
                        showForm={showForm}
                        currentEditField={currentEditField}
                        setCurrentEditField={(data) => { setCurrentEditField(data) }}
                        allowDrop={(data) => allowDrop(data)}
                        allFieldDetails={tempAllField}
                        formDetails={formDetails}
                        editElement={() => setEditModal(true)}
                        drop={(data, sequence) => drop(data, sequence)}
                        removeElement={(data) => removeElement(data)}
                        dragStart={(item1, item2, item3) => { dragStart(item1, item2, item3) }}
                        setCurrentDraggingElement={(data) => setCurrentDraggingElement(data)}
                        dragEnter={(e, index) => dragEnter(e, index)}
                    />
                        :(
                            <div>
                                <Row style={{ display: "flex", width: "100%", paddingInline: "5%", paddingBottom: "5%", paddingTop: "3%" }}>
                                    <Col md={4}>
                                        <Form.Group className="mb-2" controlId="formGridCategory">
                                            <Form.Label>Select A Category</Form.Label>
                                            <Form.Select onChange={handleCategory} value={category}>
                                                <option value="">Select Category</option>
                                                <option value="all">All</option>
                                                {selectedCategory.map((item, index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{ display: "flex", width: "100%", paddingInline: "5%", paddingBottom: "5%", paddingTop: "3%" }}>
                                    {formTemplate?.data?.map((item, index) => {
                                        const dataform = item.custom_form_data;
                                        return (
                                            <Col key={index} md={4} style={{ marginBottom: "3rem" }}>
                                                <div style={{ background: "#e3e5f5", overflow: "hidden", height: "200px", position: "relative" }}>
                                                    <img src='https://files.jotform.com/jotformapps/new-customer-registration-form-e695b96ddb999f8e5877db26805f1cb7-scrollable.png' alt='img' style={{ padding: "10px" }} />
                                                    <div className='template-shadow'></div>
                                                </div>
                                                <div className='template-header'><h3>{dataform.label}</h3></div>
                                                <div className='template-btn' onClick={()=>{activeTemplate(item.form_id)}}>Use Template</div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        )}
                    </Card>
                    <UpdateCustomFormTemplate showModal={showFormEditModal} handleClose={() => setShowFormEditModal(false)} selectedData={props.formDetails} />
                </Col>
            )}
            <Col onClick={() => props.setCurrentEditField(null)}></Col>
        </Row>
    );
}

const mapStateToProps = state => ({
    token: state.usersReducer.token,
    allCategory: state.formsReducer.allCategory,
    templateForm: state.formsReducer.templateForm,
    templateAllField:state.formsReducer.templateAllField,
});

const mapDispatchToProps = dispatch => ({
    fetchFormTemplateAllCategory: (accessToken) => dispatch(fetchFormTemplateAllCategory(accessToken)),
    fetchFormTemplateByCategory: (accessToken, category, callback) => dispatch(fetchFormTemplateByCategory(accessToken, category, callback)),
    
    fetchActiveTemplateForm: (accessToken, form_id, callback) => dispatch(fetchActiveTemplateForm(accessToken, form_id, callback)),
    fetchTemplateAllFields: (accessToken, form_id) => dispatch(fetchTemplateAllFields(accessToken, form_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateForm);

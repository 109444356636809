import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { fetchFields } from '../action';
import Select from 'react-select';
import { fetchAllGroups } from '../../groupManagement/action';
import { configureWorkflow, fetchconfigureWorkflow } from '../../workflows/action';
import { setNotification } from '../../common/action';

const ConfigureWorkflowModal = (props) => {
    const { fields, workflowStateList, showModal, handleClose, getFields, token } = props;
    const [fieldStates, setFieldStates] = useState({});
    const [allVisible, setAllVisible] = useState(false);
    const [allDisable, setAllDisable] = useState(false);
    const [state, setState] = useState("");
    const [groups, setGroups] = useState([]);  // Store selected groups as an array
    const [defaultValue, setDefaultValue] = useState("");  // Rename 'dafault' to 'defaultValue'
const[apidata,setApidata] = useState()
    // Initialize fieldStates once `fields` is available
    useEffect(() => {
        if (fields?.length) {
            const initialFieldStates = fields.reduce((acc, field) => {
                acc[field.id] = { visible: false, disable: false, defaultValue: "" }; // Include 'defaultValue'
                return acc;
            }, {});
            setFieldStates(initialFieldStates);
        }
    }, [fields]);

    const handleVisibleChange = (e) => {
        const value = e.target.checked;
        setAllVisible(value);
        setFieldStates(prevStates =>
            Object.keys(prevStates).reduce((acc, id) => {
                acc[id] = { ...prevStates[id], visible: value };
                return acc;
            }, {}));
    };

    const handleIndividualVisibilityChange = (id, value) => {
        setFieldStates(prevStates => {
            const updatedStates = {
                ...prevStates,
                [id]: { ...prevStates[id], visible: value }
            };
            const allVisibleCheck = Object.values(updatedStates).every(field => field.visible);
            setAllVisible(allVisibleCheck);
            return updatedStates;
        });
    };

    const handleDisableChange = (e) => {
        const value = e.target.checked;
        setAllDisable(value);
        setFieldStates(prevStates =>
            Object.keys(prevStates).reduce((acc, id) => {
                acc[id] = { ...prevStates[id], disable: value };
                return acc;
            }, {}));
    };

    useEffect(() => {
        if (props.token?.access) {
            props.fetchAllGroups(props.token.access);
        }
    }, [props.token?.access, props.fetchAllGroups]);

    const groupOptions = props.groups?.results?.map((item) => ({
        value: item.id,
        label: item.group_name
    })) || [];

    const handleGroupChange = (selectedOptions) => {
        setGroups(selectedOptions);  // Set the selected groups as an array of objects
    };

    // const handleConfigureWorkflow = (e) => {
    //     e.preventDefault();

    //     // Get all the selected group ids and states
    //     const selectedGroups = groups.map(group => ({group_id:group.value}));  // Extract only the values from selected groups
    //     const selectedFields = Object.keys(fieldStates).map(id => ({
    //         field: id,
    //         visible: fieldStates[id].visible,
    //         disable: fieldStates[id].disable,
    //         default: fieldStates[id].defaultValue // Use the correct field name
    //     }));

    //     let requestBody = {
    //         "groups": selectedGroups,  // Pass selected group ids as an array
    //         "fields": selectedFields  // Send fields with their visibility and disable states
    //     };

    //     props.configureWorkflow(props.token.access, props.formId, state, requestBody);
    //     handleClose();
    //     setGroups([])
    //     setDefaultValue("")
    //     setAllVisible(false)
    //     setAllDisable(false)
    // };

    const handleConfigureWorkflow = (e) => {
        e.preventDefault();
    
    
    
    if(state && groups){
        
        const selectedGroups = groups.map(group => ({ group_id: group.value }));
        const selectedFields = Object.keys(fieldStates).map(id => ({
            field: id,
            visible: fieldStates[id].visible,
            disable: fieldStates[id].disable,
            default: fieldStates[id].defaultValue
        }));
        const requestBody = {
            groups: selectedGroups,
            fields: selectedFields
        };
        props.configureWorkflow(
            props.token.access,
            props.formId,
            state,
            requestBody
        );
    
        // Close and reset after action is dispatched
        handleClose();
        resetModalData();
    }
    else {
        props.setNotification(true, "Please Select A State And Group.", "danger")
    }
    };
    const resetModalData = () => {
        setGroups([]);
        setFieldStates({});
        setState("");
        setAllVisible(false);
        setAllDisable(false);
    };
    const stateChange = (e) => {
        const selectedState = e.target.value;
        setState(selectedState);
    
        props.fetchconfigureWorkflow(
            props.token.access,
            props.formId,
            selectedState,
            (response) => {
                console.log(response, 'response');
                setApidata(response)
            }
        );
    };
    useEffect(() => {
        if (apidata) {
            const updatedFieldStates = fields.reduce((acc, field) => {
                const fieldData = apidata?.fields?.find(item => item.field === field.id) || {};
                acc[field.id] = {
                    visible: fieldData.visible || false,
                    disable: fieldData.disable || false,
                    defaultValue: fieldData.default || ""
                };
                return acc;
            }, {});
            setFieldStates(updatedFieldStates);
            const groupdata = apidata?.groups?.map(group => ({value: group.id,
                label: group.group_name }));
            setGroups(groupdata)
        }
    }, [apidata, fields]);

    return (
        <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Configure Workflow</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Select
                    placeholder="Select A State"
                    aria-label="Default select example"
                    style={{
                        width: '100%',
                        padding: '0.375rem 0.75rem',
                        border: '1px solid #ced4da',
                        color: '#495057',
                        marginBottom: '15px'
                    }}
                    value={state}
                    onChange={stateChange}
                >
                    <option value="">Select State</option>
                    {workflowStateList?.results?.map((item, index) => (
                        <option value={item.id} key={index}>
                            {item.label}
                        </option>
                    ))}
                </Form.Select>

                <Select
                placeholder="Select Group"
                    onChange={handleGroupChange}
                    value={groups}  // Set the selected groups array
                    isMulti
                    name="group"
                    options={groupOptions}
                    className="basic-multi-select"
                    classNamePrefix="select Group"
                />

                <div className="mt-2 pt-3">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Fields</th>
                                <th>
                                    <Form.Check
                                        label="Visible"
                                        checked={allVisible}
                                        onChange={handleVisibleChange}
                                    />
                                </th>
                                <th>
                                    <Form.Check
                                        label="Disable"
                                        checked={allDisable}
                                        onChange={handleDisableChange}
                                    />
                                </th>
                                <th>Default</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields?.map((row) => (
                                <tr key={row.id}>
                                    <td>{row.label}</td>
                                    <td>
                                        <Form.Check
                                            checked={fieldStates[row.id]?.visible || false}
                                            onChange={(e) => {
                                                const value = e.target.checked;
                                                handleIndividualVisibilityChange(row.id, value);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            checked={fieldStates[row.id]?.disable || false}
                                            onChange={(e) => {
                                                const value = e.target.checked;
                                                setFieldStates(prevStates => ({
                                                    ...prevStates,
                                                    [row.id]: { ...prevStates[row.id], disable: value }
                                                }));
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Form.Select
                                            placeholder="Select Default"
                                            aria-label="Default select example"
                                            style={{
                                                width: '100%',
                                                padding: '0.375rem 0.75rem',
                                                border: '1px solid #ced4da',
                                                color: '#495057',
                                                marginBottom: '15px'
                                            }}
                                            value={fieldStates[row.id]?.defaultValue || ""}
                                            onChange={(e) => {
                                                setFieldStates(prevStates => ({
                                                    ...prevStates,
                                                    [row.id]: { ...prevStates[row.id], defaultValue: e.target.value }
                                                }));
                                            }}
                                        >
                                            <option value="">Select</option>
                                            <option value="Current User">Current User</option>
                                            <option value="Current Time">Current Time</option>
                                            <option value="Current Date">Current Date</option>

                                        </Form.Select>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} variant="warning" size="sm">
                    CANCEL
                </Button>
                <Button variant="success" onClick={handleConfigureWorkflow} size="sm">
                    ADD
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
    fields: state.formsReducer.fields,
    workflowStateList: state.workflowReducer.workflowStateList,
    groups: state.groupReducer.groups,
});

const mapDispatchToProps = (dispatch) => ({
    getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
    fetchAllGroups: (accesstoken) => dispatch(fetchAllGroups(accesstoken)),
    fetchconfigureWorkflow:(accessToken, template_id, state_id, fun) => dispatch(fetchconfigureWorkflow(accessToken, template_id, state_id,fun)),
    configureWorkflow: (accessToken, template_id, state_id, requestBody) => dispatch(configureWorkflow(accessToken, template_id, state_id, requestBody)),
    setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureWorkflowModal);

import React, { Component } from 'react';
import { Table, Pagination, Col, Row, Form, Button } from 'react-bootstrap';

const ITEM_PER_PAGE_LIST = 10;

export default class DataTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            filters: {} // Holds all filter dropdown and input values
        };
    }

    getPageCount() {
        return Math.ceil(parseInt(this.props.totalItemsCount) / parseInt(ITEM_PER_PAGE_LIST));
    }

    handlePageChange(pageNumber) {
        this.setState({ currentPage: pageNumber });
        this.props.onPageChange(pageNumber);
    }

    handleDropdownChange = (feildid, selectedValue) => {
        this.setState((prevState) => {
            const filters = {
                ...prevState.filters,
                [`${feildid}option`]: selectedValue,
                [`${feildid}value`]: selectedValue ? prevState.filters[`${feildid}value`] : ""
            };
    
            return { filters };
        });
    };
    

    handleInputChange = (feildid, inputValue) => {
        this.setState((prevState) => ({
            filters: {
                ...prevState.filters,
                [`${feildid}value`]: inputValue
            }
        }));
    };

    applyFilters = () => {
        const filterQuery = Object.keys(this.state.filters)
            .filter(key => key.endsWith("option"))
            .map(optionKey => {
                const valueKey = optionKey.replace("option", "value");
                const optionValue = this.state.filters[optionKey];
                const filterValue = this.state.filters[valueKey];
    
                return `${optionValue}:${filterValue}`;
            })
            .join(",");
        this.props.multifilter(filterQuery);
    };

    clearFilters = () => {
        this.setState({ filters: {} });
        this.props.clearfilter();
    };

    renderPaginationButton() {
        let page = [];
        if (this.getPageCount() < 10) {
            for (let i = 1; i <= this.getPageCount(); i++) {
                page.push(
                    <Pagination.Item key={i} active={i === this.state.currentPage}
                        onClick={() => this.handlePageChange(i)}>{i}</Pagination.Item>
                );
            }
        } else {
            page.push(
                <Pagination.Item key={1} active={1 === this.state.currentPage}
                    onClick={() => this.handlePageChange(1)}>{1}</Pagination.Item>
            );
            {
                this.state.currentPage === 2 ?
                    page.push(
                        <Pagination.Item key={2} active={2 === this.state.currentPage}
                            onClick={() => this.handlePageChange(2)}>{2}</Pagination.Item>) :
                    page.push(
                        <Pagination.Ellipsis key="ellipsis1" />
                    );
            }
            {
                this.state.currentPage > 2 && this.state.currentPage < this.getPageCount() - 1 ?
                    page.push(
                        <Pagination.Item key={Math.ceil(this.state.currentPage)} active={this.state.currentPage}
                            onClick={() => this.handlePageChange(this.state.currentPage)}>{this.state.currentPage}</Pagination.Item>
                    ) : <></>;
            }
            {
                this.state.currentPage === this.getPageCount() - 1 ?
                    page.push(
                        <Pagination.Item key={this.getPageCount() - 1} active={this.getPageCount() - 1 === this.state.currentPage}
                            onClick={() => this.handlePageChange(this.getPageCount() - 1)}>{this.getPageCount() - 1}</Pagination.Item>) :
                    page.push(
                        <Pagination.Ellipsis key="ellipsis2" />
                    );
            }
            page.push(
                <Pagination.Item key={this.getPageCount()} active={this.getPageCount() === this.state.currentPage}
                    onClick={() => this.handlePageChange(this.getPageCount())}>{this.getPageCount()}</Pagination.Item>
            );
        }
        return (
            <Pagination size="sm">
                <Pagination.First onClick={() => this.handlePageChange(1)} />
                <Pagination.Prev onClick={() => this.handlePageChange(this.state.currentPage > 1 ? this.state.currentPage - 1 : 1)} />
                {page}
                <Pagination.Next onClick={() => this.handlePageChange(this.state.currentPage < this.getPageCount() ? this.state.currentPage + 1 : this.getPageCount())} />
                <Pagination.Last onClick={() => this.handlePageChange(this.getPageCount())} />
            </Pagination>
        );
    }

    renderTableDataCountStats() {
        let startCounter = parseInt(this.state.currentPage - 1) * parseInt(ITEM_PER_PAGE_LIST) + 1;
        let endCounter = parseInt(this.state.currentPage) * parseInt(ITEM_PER_PAGE_LIST);
        if (endCounter > parseInt(this.props.totalItemsCount)) {
            endCounter = this.props.totalItemsCount;
        }
        return `Showing ${startCounter} to ${endCounter} of ${this.props.totalItemsCount} entries`;
    }

    render() {
        return (
            <div>
                <Table responsive striped bordered hover style={{ overflowX: "auto" }}>
                    <thead>
                        <tr>
                            {
                                this.props.headers.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.body.length === 0 ?
                            <tr className="no-data">
                                <td style={{ width: "100vw" }} colSpan={this.props.headers.length}>
                                    <div className="text-center">No Data Available</div>
                                </td>
                            </tr>
                            :
                            this.props.body.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((td, cellIndex) => (
                                        <td key={cellIndex}>{td}</td>
                                    ))}
                                </tr>
                            ))
                        }
                    </tbody>
                    {this.props.filter && (
                        <tfoot>
                        <tr>
                            {this.props.Id?.map((feildid, index) => (
                                <td key={feildid}>
                                    <Form.Control
                                        as="select"
                                        className="mb-2"
                                        onChange={(e) => this.handleDropdownChange(feildid, e.target.value)}
                                        value={this.state.filters[`${feildid}option`] || ""}
                                    >
                                        <option value="">Select Filter</option>
                                        <option value={`${feildid}:_eq`}>equals to</option>
                                        <option value={`${feildid}:_ne`}>not equals to</option>
                                        <option value={`${feildid}:_like`}>contains</option>
                                        <option value={`${feildid}:_gt`}>greater than</option>
                                        <option value={`${feildid}:_ge`}>greater than equals to</option>
                                        <option value={`${feildid}:_lt`}>less than</option>
                                        <option value={`${feildid}:_le`}>less than equals to</option>
                                        <option value={`${feildid}:_between`}>between</option>
                                        <option value={`${feildid}:_notbetween`}>not Between</option>
                                        <option value={`${feildid}:_like`}>like</option>
                                        <option value={`${feildid}:_notlike`}>not like</option>
                                        <option value={`${feildid}:_in`}>in</option>
                                        <option value={`${feildid}:_notin`}>not in</option>
                                    </Form.Control>
                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter text"
                                        onChange={(e) => this.handleInputChange(feildid, e.target.value)}
                                        value={this.state.filters[`${feildid}value`] || ""}
                                        disabled={!this.state.filters[`${feildid}option`]}
                                    />
                                </td>
                            ))}
                        </tr>
                        <Button
                            variant="primary"
                            size="sm"
                            style={{ margin: "10px", float: "right" }}
                            onClick={this.applyFilters}
                        >
                            Apply Filter
                        </Button>
                        <Button
                            variant="secondary"
                            size="sm"
                            style={{ margin: "10px", float: "right" }}
                            onClick={this.clearFilters}
                        >
                            Clear
                        </Button>
                    </tfoot>
                    
                    )}
                </Table>
                <div className="pagination-btn">
                    <Row>
                        <Col md="8">
                            <div className={'mt-2'}>
                                <b>{this.renderTableDataCountStats()}</b>
                            </div>
                        </Col>
                        <Col md="4" className='d-flex justify-content-end'>
                            {this.getPageCount() > 1 ?
                                this.renderPaginationButton() :
                                null}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

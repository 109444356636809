import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { REQUEST_METHOD } from '../../../lib/constants';
import { fetchApi } from '../../../lib/utils';
import { updateLoader } from '../../common/action';
import { fetchMultiSearchData } from '../../forms/action';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {}, // Stores options for each dropdown by field ID
        };
    }

    componentDidMount() {
        this.fetchOptions();
    }

    fetchOptions = () => {
        const { fieldData, ShareToken, token, setLoader } = this.props;
        const fieldId = fieldData.id;

        // Fetching options based on master_data_code
        if (fieldData.master_data_code !== 'string') {
            setLoader(true);
            const url = token
                ? `/forms/template/${fieldData.master_data_code}/data/?all=true`
                : `/forms/template/${fieldData.master_data_code}/${ShareToken}/data/?all=true`;

            fetchApi(
                url,
                token?.access,
                REQUEST_METHOD.GET,
                (successResponse) => {
                    const data = successResponse.data || [];
                    const options = data.flatMap(row => 
                        row.data.map(item => item.value) // Extracting `value` property
                    );

                    // Set options for this specific field ID
                    this.setState(prevState => ({
                        options: { ...prevState.options, [fieldId]: options },
                    }));
                    setLoader(false);
                },
                () => setLoader(false)
            );
        } else if (fieldData.values) {
            setLoader(true);
            const options = fieldData.values.includes(',')
                ? fieldData.values.split(',').map(item => item.trim())
                : [fieldData.values];

            this.setState(prevState => ({
                options: { ...prevState.options, [fieldId]: options },
            }));
            setLoader(false);
        }
    };

    // handleChange = (event) => {
    //     const selectedValue = event.target.value;
    //     const selectedIndex = event.target.selectedIndex - 1; // Adjust for placeholder
    //     const { fieldData, onChange } = this.props;
    //     if (selectedIndex < 0) {
    //         this.props.onChange({ value: '', field: fieldData.id }); // Clear selection if placeholder is selected
    //         return;
    //     }

    //     this.props.onChange({ value: selectedValue, field: fieldData.id }); // Notify parent component of the selected value

    //     // Fetch data based on the selected value
    //     const selectedDetailArray = fieldData?.event[selectedIndex]?.datas || [];
    //     const forDatas = selectedDetailArray.map(data => {
    //         const { datasource_template, filter, response } = data;
    //         return { key: filter, value: selectedValue, datasource_template, response };
    //     });

    //     if (forDatas.length > 0) {
    //         const firstData = forDatas[0];
    //         const forFieldOptions = `'${firstData.key}:${selectedValue}'`;

    //         this.props.fetchMultiSearchData(
    //             this.props.token?.access,
    //             // firstData.datasource_template,
    //             124,
    //             forFieldOptions,
    //             firstData.response,
    //             (apiResponse) => {
    //                 const nextFieldOptions = apiResponse.data || [];
                   
    //                 // Update state with the new options for the dropdown associated with this field ID
    //                 this.setState(prevState => ({
    //                     options: {
    //                         ...prevState.options,
    //                         [this.props.fieldData.id]: nextFieldOptions, // Use fieldData.id to bind data
    //                     },
    //                 }));
    //             }
    //         );
    //     }
    // };
    parseOptions = (data) => {
        if (Array.isArray(data) && typeof data[0] === 'string') {
            return data;
        }
        return data.flatMap(item =>
            item.data.map(subItem => subItem.value)
        );
    };

    handleChange = (event) => {
        const selectedValue = event.target.value;
        const selectedIndex = event.target.selectedIndex - 1;
        const { fieldData, onChange } = this.props;

        if (selectedIndex < 0) {
            onChange(''); // Clear the selection if placeholder is selected
            return;
        }

        onChange(selectedValue);

        const eventArray = fieldData?.event || [];
        eventArray.forEach(({ for_field, datas }) => {
            console.log(for_field,'for_field',datas,'datas')
            datas.forEach(({ datasource_template, filter, response }) => {
                const forFieldOptions = `'${filter}:${selectedValue}'`;

                this.props.fetchMultiSearchData(
                    this.props.token?.access,
                    datasource_template,
                    forFieldOptions,
                    response,
                    (apiResponse) => {
                        const responseData = apiResponse.data || [];
                        const parsedOptions = this.parseOptions(responseData);
                       
                        this.setState({ options: { ...this.state.options, [for_field]: parsedOptions }});
                        
                        // this.setState(prevState => ({
                        //     options: {
                        //         ...prevState.options,
                        //         [for_field]: parsedOptions 
                        //     },
                        // }));
                        
                    }
                );
            });
        });
    };
    render() {
       
        console.log(this.props.fieldData,'fieldData')
       console.log(this.state.options)
        const { fieldData, selected, required, editable } = this.props;
        const currentOptions = this.state.options[fieldData.id] || []; // Get options for the current field ID

        return (
            <Form.Select
                value={selected} // Bind the selected value
                required={required}
                disabled={editable}
                title={fieldData.tooltip}
                aria-label="Default select example"
                onChange={this.handleChange}
            >
                <option value="">-{fieldData.placeholder}-</option>
                {currentOptions.map((option, index) => (
                    <option value={option} key={index}>{option}</option>
                ))}
            </Form.Select>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (status) => dispatch(updateLoader(status)),
    fetchMultiSearchData: (accessToken, template_id, query, response_id, callback) =>
        dispatch(fetchMultiSearchData(accessToken, template_id, query, response_id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);

import { DEFAULT_DATA } from '../../lib/constants';
import { FETCH_ALL_GROUP, FETCH_TEAM, FETCH_TEAM_MEMBERS } from './actionTypes';

const initialState = {
    // teams: DEFAULT_DATA,
    // teamData: {},
    // teamMembers: DEFAULT_DATA
    groups:{}
};

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_GROUP:
            return {
                ...state,
                groups: action.payload
            };
       

        default:
            return state;
    }
};

export default groupReducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, } from 'react-bootstrap';
import { checkUnique, fetchAllFormsList, fetchData, fetchFields, updateFormData } from '../action';
import { removeIdFromData, getFieldValue, validateForm, redirect } from '../../../lib/utils';
import CustomFieldHandler from '../../customforms/customfields/FieldHandler';
import { setNotification, updateLoader } from '../../common/action';
import FieldHandler from './fields/FieldHandler';
import Breadcrumbs from '../../common/components/Breadcrumbs';

class EditDataDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data_obj: {},
            formDetails: {},
            validationResponse: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data.data });
            this.props.getAllForms(this.props.token.access);
        }
        if (this.props.allFormList !== prevProps.allFormList) {
            this.props.updateLoader(true);
            this.props.allFormList.data && this.props.allFormList.data.length > 0 && this.props.allFormList.data.map((item, index) => {
                if (item.id == this.props.params.template_id) {
                    this.setState({ formDetails: item })
                    this.props.updateLoader(false);
                }
            })
        }
    }

    componentDidMount() {
        this.props.getData(this.props.token.access, this.props.params.id);
        this.props.getFields(this.props.token.access, this.props.params.template_id);
    }

    handleDataChange(fieldId, value) {
        let data = this.state.data
        let flag = true;
        for (var i = 0; i < data.length; i++) {
            if (data[i].field === fieldId) {
                flag = false;
                data[i].value = value;
                break;
            }
        }
        if (flag) {
            data.push({
                value: value,
                field: fieldId
            })
        }
        this.setState({ data: data })
    }

    handleSubmitAction(e) {
        e.preventDefault()
        if (validateForm(this.props.fields, this.state.data)) {
            var my_data = this.props.data.data.map((ele, index) => {
                return {
                    "value": ele.value,
                    "field": ele.field
                }
            });
            if (Object.keys(this.state.data_obj).length > 0) {
                my_data = my_data.length > 0 && my_data.map((item, index) => {
                    if (Object.keys(this.state.data_obj).includes(String(item.field))) {
                        item.value = this.state.data_obj[item.field]
                    }
                    return item
                })
                var x = my_data.map((ele, i) => {
                    return ele.field
                })
                Object.keys(this.state.data_obj).map((item, i) => {
                    if (!x.includes(item)) {
                        my_data.push({
                            "value": this.state.data_obj[item],
                            "field": parseInt(item)
                        })
                    }
                })
            }
            let requestBody = {
                "data": my_data,
                "is_delete": false,
                "lock_status": this.props.data.lock_status,
                "template": this.props.data.template
            }

            this.props.updateData(this.props.token.access, this.props.data.template, requestBody, this.props.data.id, this.props.navigate);
        }
    }

    render() {
        return (
            <div>
                <div style={{ marginLeft: 20, marginTop: 20 }}>
                    <div className='m-2'>
                        <Breadcrumbs name={this.props} disabledStrings={[this.props.params.id, this.props.params.template_id]} />
                    </div>
                    <span style={{ margin: 10 }}>Edit Form Templates</span>
                </div>

                <div className="container p-4 container-fluid">
                    <Row>
                        <Col>

                            {
                                this.state.formDetails.type == "C" &&
                                <Card>
                                    <Card.Header>
                                        <div style={{ backgroundImage: `url(${this.state.formDetails.image_header})`, paddingTop: "5%", height: "100%", width: "100%", backgroundSize: "cover", paddingBlock: 20 }}>
                                            <h3 style={{ marginInline: "5%" }}>{this.state.formDetails.label && this.state.formDetails.label}</h3>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div>
                                            <Row style={{ display: "flex", paddingBlock: "3%" }} className='custom-form'>
                                                {
                                                    this.props.fields.length > 0 && this.props.fields.map((field, index) => {
                                                        return (
                                                            <Col md={field.style == '' ? 12 : field.style} style={{ display: "flex", justifyContent: "center", marginBottom: 25 }}
                                                                key={index}>
                                                                <CustomFieldHandler
                                                                    data={field}
                                                                    type={field.type}
                                                                    editable={false}
                                                                    value={getFieldValue(field, this.state.data, false)}
                                                                    onChange={(data) => {
                                                                        var new_obj = { ...this.state.data_obj }
                                                                        new_obj[data.field] = data.value
                                                                        this.setState({ data_obj: new_obj })
                                                                    }}
                                                                    onBlur={() => { }}
                                                                    // onBlur={(data) => {
                                                                    //     if (data !== '') {
                                                                    //         this.props.checkUnique(this.props.token.access, this.props.params.template_id, field.id, data, (resp) => {
                                                                    //             if (resp.status === true) {
                                                                    //                 this.props.setNotification(true, 'Already exists', "danger");
                                                                    //                 this.setState({ validationResponse: true })
                                                                    //             }
                                                                    //         });
                                                                    //     }
                                                                    // }}
                                                                />
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                            <Row className='d-flex justify-content-center'>
                                                <Col md={2}>
                                                    <Button style={{ width: "100%" }} onClick={(e) => { this.handleSubmitAction(e) }} variant="outline-success" className='mb-3'>UPDATE</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                </Card>
                            }
                            {
                                this.state.formDetails.type == "N" &&
                                <Card>
                                    <Card.Body>
                                        <div>
                                            <Row>
                                                {this.props.fields.map((field, index) => {
                                                    return <FieldHandler
                                                        fieldData={field}
                                                        index={index}
                                                        value={getFieldValue(field, this.state.data, false)}
                                                        handleDataChange={(field_id, value) => this.handleDataChange(field_id, value)}
                                                        onBlur={() => { }}
                                                    />
                                                })}
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Button onClick={(e) => { this.handleSubmitAction(e) }} variant="outline-success" className='mt-4'>UPDATE</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                </Card>
                            }

                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fields: state.formsReducer.fields,
        token: state.usersReducer.token,
        data: state.formsReducer.data,
        allFormList: state.formsReducer.allFormList,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        updateData: (accessToken, templateId, requestBody, id, navigate) => dispatch(updateFormData(accessToken, templateId, requestBody, id, navigate)),
        getData: (accessToken, templateId) => dispatch(fetchData(accessToken, templateId)),
        checkUnique: (accessToken, templateId, fieldId, value, returnedData) => dispatch(checkUnique(accessToken, templateId, fieldId, value, returnedData)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
        getAllForms: (accessToken) => dispatch(fetchAllFormsList(accessToken)),
        updateLoader: (status) => dispatch(updateLoader(status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDataDetails);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, Image } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { createOrganization, fetchOrganizations, deleteOrganization } from '../action';
import OrganizationsUpdateModal from './OrganizationsUpdateModal';
import { copyTextToClipboard } from '../../common/action';
import { formateDate } from '../../../lib/utils';
import DeleteModal from '../../common/components/DeleteModal';
import { setNotification } from '../../common/action';
import { Link } from 'react-router-dom';

class Organizations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            logo: '',
            website: '',
            email: '',
            phone: '',
            about: '',
        }
    }

    componentDidMount() {
        this.props.getOrganizations(this.props.token.access);
    }

    deleteAction(event) {
        this.props.removeOrganization(this.props.token.access, event.id);
    }

    handleCreateAction(event) {
        event.preventDefault()
        let requestBody = {
            name: this.state.name,
            logo: this.state.logo,
            website: this.state.website,
            email: this.state.email,
            phone: this.state.phone,
            about: this.state.about
        }

        if (requestBody["website"].slice(0, 8) !== "https://") {
            requestBody["website"] = "https://" + requestBody["website"];
        }

        if (requestBody["name"].replace(/\s/g, '').length) {
            this.props.addOrganization(this.props.token.access, requestBody);
        }
        else {
            this.props.setNotification(true, "please enter a valid name", "danger");
        }
        
        this.setState({
            name: "",
            logo: "",
            website: "",
            email: "",
            phone: "",
            about: "",
        })
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid" >
                <Row>
                    <Col>
                        <p>Organizations</p>
                        <Card>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => { this.handleCreateAction(e) }}>
                                    <Row>
                                        <Col className='m-4'>
                                            <Form.Label>Organization Name <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Organization Name" required value={this.state.name} onChange={event => { this.setState({ name: event.target.value }); }} />
                                        </Col>
                                        <Col className='m-4'>
                                            <Form.Label>Logo<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="file" placeholder="upload The Logo" required
                                                onChange={event => { this.setState({ logo: event.target.files[0] }) }} />
                                        </Col>
                                        <Col className='m-4'>
                                            <Form.Label>Website<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Organization Website" required value={this.state.website} onChange={event => { this.setState({ website: event.target.value }) }} />
                                        </Col>
                                        <Col className='m-4'>
                                            <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="email" placeholder="Enter e-Mail" required value={this.state.email}
                                                onChange={event => { this.setState({ email: event.target.value }); }} />
                                        </Col>
                                        <Col className='m-4'>
                                            <Form.Label>Phone<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size="sm" type="text" placeholder="Enter Phone" required value={this.state.phone}
                                                onChange={event => { this.setState({ phone: event.target.value }); }} />
                                        </Col>
                                        <Row>
                                            <Col className='m-4'>
                                                <Form.Label>About</Form.Label>
                                                <Form.Control size="sm" type="textarea" placeholder="About" value={this.state.about} onChange={event => { this.setState({ about: event.target.value }) }} />
                                            </Col>
                                            <Col className='m-5'>
                                                <Button type='submit' variant="outline-success" size='sm'>CREATE</Button>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Form>

                                <div className='mt-3 font-medium'  >
                                    <DataTable
                                        totalItemsCount={this.props.organizations.count}
                                        headers={[`Organization`, `Created On`, `Email`, `Phone`, `Action`]}
                                        onPageChange={(pageNumber) => this.props.getOrganizations(this.props.token.access, pageNumber)}
                                        body={this.props.organizations.results.map((row, index) => {
                                            return [
                                                <Link to={row.website} target='_blank'>
                                                    <Image src={row.logo} height={60} width={60} /><br />
                                                    <strong>{row.name}</strong>
                                                </Link>,
                                                formateDate(row.date_added),
                                                row.email,
                                                row.phone,
                                                <Row>
                                                    <Col sm='3'>
                                                        <Button title='Copy-Link' variant="success" size='sm' onClick={() => { this.props.copyText(`${window.location.protocol}//${window.location.host}/${row.panel_access_url}`); }}><i class="fa-solid fa-link" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                    </Col>
                                                    <Col sm='3'>
                                                        <Button title='Edit' variant="info" onClick={() => this.setState({ showUpdateModal: true, selectedData: row })} size='sm'><i class="fa fa-pencil-square-o" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                    </Col>
                                                    <Col sm='3'>
                                                        <Link to={`/organizations/${row.id}/`} >
                                                            <Button title='View' variant="primary" size='sm'><i className="fa fa-eye" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}} /></Button>
                                                        </Link>
                                                    </Col>
                                                    <Col sm='3'>
                                                        <Button title='Delete' onClick={() => this.setState({ showDeleteModal: true, selectedData: row })} variant="danger" size='sm'><i class="fa fa-trash-o" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                    </Col>
                                                </Row>
                                            ]
                                        })
                                        } />
                                </div>
                                <DeleteModal showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                                <OrganizationsUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        organizations: state.organizationReducer.organizations,
        token: state.usersReducer.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeOrganization: (accessToken, organizationId) => dispatch(deleteOrganization(accessToken, organizationId)),
        getOrganizations: (accessToken, pageNumber) => dispatch(fetchOrganizations(accessToken, pageNumber)),
        addOrganization: (accessToken, requestBody) => dispatch(createOrganization(accessToken, requestBody)),
        copyText: (copyText) => dispatch(copyTextToClipboard(copyText)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);

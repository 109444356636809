import React, { Component } from 'react'
import Navbar from "./Navbr";
import Footer from "./lfooter";
import VideoModal from './videoModal';
import { Button, Toast } from 'react-bootstrap';
import Custom from "../../../Images/customize.png";
import Table from "../../../Images/formtable.png";
import Digitalization from "../../../Images/digitalization.png";
import Analytic from "../../../Images/analytic.png";
import Service from "../../../Images/24service.png";
import Aos from 'aos';
import { connect } from 'react-redux';
import { PlanDetails } from '../../Billing/action';
class Pricing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showVideoModal: false,
            showNotification: false,
            monthly: true,
            yearly: false
        }
    }
    onNotification = () => {
        this.setState({ showNotification: true });
        setTimeout(() => {
            this.setState({ showNotification: false })

        }, 5000);


    }
    componentDidMount() {
        Aos.init();
        this.props.PlanDetails();

    }
    render() {
        console.log(this.props.plandetails)
        return (
            <div>

                <Navbar />
                {this.state.showNotification == true}{
                    <div style={{
                        position: "fixed",
                        right: "0",
                        top: "0",
                        zIndex: "100",
                        color: "white"
                    }}>
                        <Toast show={this.state.showNotification} onClose={!this.state.showNotification}
                            bg='danger'>
                            <Toast.Body>Before choosing any plan, you must have registered.</Toast.Body>
                        </Toast>
                    </div>
                }
                <section id="hero" className="d-flex align-items-center">

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                                <h1>Pricing Plan</h1>
                                <div>Get the most out of Giga-forms with these first steps and pro tips .</div>
                                <div>
                                    <a href="http://173.212.215.65/EForm/E-Form.html

" className="btn-get-started scrollto">Help Center</a>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img">
                                <img src="https://d24uab5gycr2uz.cloudfront.net/uploads/other_pic/vXAencgmve.png" className="img-fluid animated" alt="img" />
                            </div>
                        </div>
                    </div>

                </section>
                <section className="pricing-section" style={{background:"#ffffff"}}>
                    <div className="container">
                        <div className="sec-title text-center" style={{marginBottom:"30px"}}>
                            <h2>Features and Pricing</h2>
                        </div>
                        <div>
                            <center> <div className='price-pack' style={{ border: "0.5px solid grey", height: "50px", borderRadius: "20px", display: "flex", flexDirection: "row", justifyContent: "space-evenly", }}>
                                <Button variant={this.state.monthly == true ? 'success' : 'light'} size='lg' className='m-1' style={{ border: "none", fontSize: "17px", borderRadius: "20px" }} onClick={() => { this.setState({ monthly: true, yearly: false }) }}>Monthly</Button>
                                <Button variant={this.state.yearly == true ? 'success' : 'light'} size='lg' className='m-1' style={{ border: "none", fontSize: "17px", borderRadius: "20px" }} onClick={() => { this.setState({ yearly: true, monthly: false }) }}>Yearly</Button>
                            </div></center>
                        </div>
                        <br />
                        <div className="outer-box">
                            <div className="row">

                            {this.props.plandetails && this.props.plandetails.results && this.props.plandetails.results.map((data,index)=>
                            (
                                <div className="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
                                 <div className="inner-box">
                                     <div className="icon-box">
                                     <div className="title text-white">{data.name}</div>
                                     </div>
                                      {/* <div className="w-full p-4" onClick={this.onNotification}  style={{backgroundColor: "transparent"}}><div className="d-flex justify-content-center align-items-center w-full font-weight-bold transition hover:opacity-70 radius " style={{backgroundColor: "rgb(1, 189, 111)", color: "rgb(255, 255, 255)",height:"3rem",borderRadius:"5px",fontWeight:700,cursor:"pointer"}}>Get Started</div></div> */}
                                     <div className="price-box">
                                         {/* <div className="title">{this.props.plandetails.results[0].name}</div> */}
                                         {/* <del className="price">₹100</del> */}
                                         {/* <span class="flex flex-col"><strong className="mx-2 mt-4" style={{fontWeight:700,fontSize:"30px"}}>{data.monthly_amount}</strong></span>
                                       <span></span> */}

{this.state.monthly ? (
  <h4 className="price">
    ₹
    {data.monthly_amount == 99999
      ? "Unlimited"
      : data.monthly_amount == 11111
      ? "-"
      : Math.round(data.monthly_amount / 100)}
    {data.monthly_amount != 11111 && (
      <span style={{ color: "rgb(181, 188, 197)", fontSize: "15px", fontWeight: "400" }}>
        /Month
      </span>
    )}
  </h4>
) : (
  <h4 className="price">
    ₹
    {data.yearly_amount == 99999
      ? "Unlimited"
      : data.yearly_amount == 11111
      ? "-"
      : Math.round(data.yearly_amount / 100)}
    {data.yearly_amount != 11111 && (
      <span style={{ color: "rgb(181, 188, 197)", fontSize: "15px", fontWeight: "400" }}>
        /Year
      </span>
    )}
  </h4>
)}

                                     </div> 
                                    
                                     <ul className="features" style={{padding:"0px 16px"}}>
             <li className="true">Forms: <b>{data.form_count == 99999 ?"Unlimited":data.form_count == 11111? "-":data.form_count}</b></li>
             <li className="true">Submission/entries: <b>{data.monthly_submission== 99999 ?"Unlimited":data.monthly_submission == 11111? "-":data.monthly_submission}</b></li>
             <li className="true">Field: <b>{data.field_count== 99999 ?"Unlimited":data.field_count == 11111? "-":data.field_count}</b></li>
             <li className="true">Users: <b>{data.user_count== 99999 ?"Unlimited":data.user_count == 11111? "-":data.user_count}</b></li>
             {/* <li className="true">Reports-<b>Unlimited</b></li> */}
             <li className="true">API(Distribution): <b>{data.API_Distribution== 99999 ?"Unlimited":data.API_Distribution == 11111? "-":data.API_Distribution}</b></li>
             <li className="true">Storage: <b>{data.space_assign== 99999 ?"Unlimited":data.space_assign == 11111? "-":data.space_assign}</b></li>
             {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
             <li className="true">Teams: <b>{data.team_count== 99999 ?"Unlimited":data.team_count == 11111? "-":data.team_count}</b></li>
             <li className="false">Workflow: <b>{data.workflow_count== 99999 ?"Unlimited":data.workflow_count == 11111? "-":data.workflow_count}</b></li>
             <li className="false">Custom Form: <b>{data.custome_form_count== 99999 ?"Unlimited":data.custome_form_count == 11111? "-":data.custome_form_count}</b></li>
             <li className="false">Custom Logo: <b>{data.Custom_Logo== 99999 ?"Unlimited":data.Custom_Logo == 11111? "-":data.Custom_Logo}</b></li>
            
             <li className="false">Mail Notification: <b>{data.email_count== 99999 ?"Unlimited":data.email_count == 11111? "-":data.email_count}</b></li>
             <li className="false">Field Encryption: <b>{data.Field_Encryption== 99999 ?"Unlimited":data.Field_Encryption == 11111? "-":data.Field_Encryption}</b></li>
             <li className="false">Field Tracking: <b>{data.Field_Tracking== 99999 ?"Unlimited":data.Field_Tracking == 11111? "-":data.Field_Tracking}</b></li>
             
             <li className="false">E-signature: <b>{data.e_signature_count== 99999 ?"Unlimited":data.e_signature_count == 11111? "-":data.e_signature_count}</b></li>
             <li className="false">Form Sharing: <b>{data.form_sharing== 99999 ?"Unlimited":data.form_sharing == 11111? "-":data.form_sharing}</b></li>
             <li className="false">Form Template: <b>{data.form_template== 99999 ?"Unlimited":data.form_template == 11111? "-":data.form_template}</b></li>
             <li className="false">Mobile App: <b>{data.mobile_app== 99999 ?"Unlimited":data.mobile_app == 11111? "-":data.mobile_app}</b></li>
             <li className="false">Customer Support: <b>{data.Customer_Support== 99999 ?"Unlimited":data.Customer_Support == 11111? "-":data.Customer_Support}</b></li>
             {/* <li className="false"> Submission-<b>{this.props.plandetails.results[0].monthly_submission}</b></li> */}
             {/* <li className="false">Space-<b>{this.props.plandetails.results[0].space_assign+"MB"}</b></li> */}

         </ul>
                                     {/* <div className="btn-box">
                                         <a className="theme-btn" style={{ textDecoration: "none", cursor: "pointer" }} onClick={this.onNotification}>BUY plan</a>
                                     </div> */}
                                 </div>
                                 <div className="w-full p-4" onClick={this.onNotification}  style={{backgroundColor: "transparent"}}><div className="get-button d-flex justify-content-center align-items-center w-full font-weight-bold transition hover:opacity-70 radius " style={{backgroundColor: "rgb(1, 189, 111)", color: "rgb(255, 255, 255)",padding:"6px 10px",borderRadius:"5px",fontWeight:700,cursor:"pointer"}}>Get Free</div></div>
                             
                             </div>
                            ))
                           
                            
                            }
                           </div>
                        </div>
                    </div>
                </section>
                <section  style={{background:"#ffffff"}}>
                <div className="sec-title text-center" >
                            <h2>Why Choose Gigaforms ?</h2>
                        </div>
                        <div className='container'>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                    <div className='row' style={{ maxWidth:"1100px"}}>
                        <div className='col-md-6 my-4' data-aos="zoom-in-right">
                            <div >
                                <h4 style={{ fontWeight: "700" }}>Form Customization :</h4>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Giga Forms allows you to customize your forms to match your brand identity and specific requirements. You can easily add your company logo, colors, and fonts to your forms.</p>
                            </div>
                        </div>
                        <div className='col-md-6 my-4' data-aos="zoom-in-left">
                            <img src={Custom} style={{ marginTop: "-1em",float:"right" }} alt='img'></img>
                        </div>
                        <div className='col-md-6 my-4' data-aos="zoom-in-left">
                            <img src={Table} style={{ marginTop: "-1em", width: "400", height: "300",float:"left" }} alt='img'></img>
                        </div>
                        <div className='col-md-6 my-4' data-aos="zoom-in-right">
                            <div >
                                <h4 style={{ fontWeight: "700" }}>Table Form Builder :</h4>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>With Giga Forms, you can create customized forms with ease, including table-based forms that allow you to organize data in a structured manner.</p>
                            </div>
                        </div>
                        <div className='col-md-6 my-4' data-aos="zoom-in-right">
                            <div >
                                <h4 style={{ fontWeight: "700" }}>Digitization Platform :</h4>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center", fontsize: "10px" }}>With Giga Forms, you can digitize your entire form-building process, reducing paper waste and improving efficiency.</p>
                            </div>
                        </div>
                        <div className='col-md-6 my-4' data-aos="zoom-in-left">
                            <img src={Digitalization} style={{ marginTop: "-1em", width: "400", height: "300",float:"right" }} alt='img'></img>
                        </div>
                        <div className='col-md-6 my-4' data-aos="zoom-in-left">
                            <img src={Analytic} style={{ marginTop: "-1em", width: "400", height: "300",float:"left" }} alt='img'></img>
                        </div>
                        <div className='col-md-6 my-4' data-aos="zoom-in-right">
                            <div >
                                <h4 style={{ fontWeight: "700" }}>Form Analytics and Reporting :</h4>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center", fontsize: "10px" }}>Giga Forms provides in-depth analytics and reporting features, enabling you to gain insights into form performance and make data-driven decisions.</p>
                            </div>
                        </div>
                        <div className='col-md-6 my-4' data-aos="zoom-in-right">
                            <div >
                                <h4 style={{ fontWeight: "700" }}>24/7 Customer Support :</h4>
                                <p className='text-right' style={{ display: "flex", justifyContent: "center", alignItems: "center", fontsize: "10px" }}>Our friendly support team is available to help you anytime you need them.</p>
                            </div>
                        </div>
                        <div className='col-md-6 my-4' data-aos="zoom-in-left">
                            <img src={Service} style={{ marginTop: "-1em", width: "400", height: "300",float:"right" }} alt='img'></img>
                        </div>
                    </div>
                </div>
                </div>
                </section>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        plandetails: state.billingReducer.plandetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        PlanDetails: () => dispatch(PlanDetails())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);

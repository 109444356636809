import axios from 'axios';
import React, { Component } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { base64ToUrl, redirect } from '../../../lib/utils';
import Toaster from "../../common/components/Toaster";
import { createCompanyProfile, fetchBillingUser, PlanDetails, UpgradePlan } from '../action';
var CryptoJS = require("crypto-js");

// var w

class BillingRegister extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      phone: "",
      GST: "",
      free: false,
      designation: "",
      contactperson: "",
      address: "",
      modalVisible: false,
      decodedString: '',
      country: '',
      loading: true,
      monthly: true,
      yearly: false,
      duration: null,
      subscription_type: ""

    }
  }
  handleChange = (event) => {
    const { value } = event.target;

    if (/^\d*$/.test(value)) {
      this.setState({ phone: value });
    }
  }

  componentDidMount() {
    this.props.billingUser(this.props.token.access);
    // const { latitude, longitude } = this.props;
    if (localStorage.getItem("latitude") !== null) {
      axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${localStorage.getItem("latitude").toString()},${localStorage.getItem("longitude").toString()}&key=AIzaSyDjCX0hCACCUVwfOsI5uWVouJv7rJtGgn0
      `)
        .then(response => {
          const addressComponents = response.data.results[0].address_components;
          const countryComponent = addressComponents.find(component => component.types.includes('country'));
          const country = countryComponent.long_name;
          this.setState({ country, loading: false });
        })
        .catch(error => {
          console.error(error);
          this.setState({ loading: false });
        });
    };
    this.props.PlanDetails()

  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.billinguser !== this.props.billinguser) {
      this.setState({
        name: this.props.billinguser.user && this.props.billinguser.user.company_name,
        phone: this.props.billinguser.user && this.props.billinguser.user.phone,
        email: this.props.billinguser.user && this.props.billinguser.user.email

      })
    }

  }




  onCreateOrder = (type, duration) => {
    console.log("STATE",this.state)
    if(this.props.billinguser.id==undefined){
      let requestBody = {
        "billing_name": this.state.name,
        "contact_no": this.state.phone,
        "email": this.state.email,
        "gst_no": this.state.GST,
        "address": this.state.address,
        "contact_person": this.state.contactperson,
        "designation": this.state.designation,
        "subscription_type": type,
        "duration": duration
  
  
      }
      this.props.createCompany(this.props.token.access, requestBody)
    }
    else{
      let requestBody2={
        "subscription_type": type,
        "duration": duration,
        "billing":this.props.billinguser.id

      }
      this.props.UpgradePlan(this.props.token.access,requestBody2)
    }
   
    this.setState({
      name: "",
      email: "",
      phone: "",
      GST: "",
    })



  }



  render() {
    return (
      <>
        <div>
          <Modal show={this.state.modalVisible} onHide={this.handleCloseModal} backdrop="static" keyboard={false} centered={true} size={'lg'}>
            <Modal.Body >
              <iframe src={this.state.decodedString} title="description" style={{ height: window.innerHeight / 1.3, width: "100%" }}></iframe>
            </Modal.Body>
          </Modal>
        </div>
        <div className="main-content-container p-4 container-fluid" >
          <Row style={{ position: "absolute", right: 0, top: 0 }} >
            <Col>
              <Toaster />
            </Col>
          </Row>
          {this.props.billinguser.active !== "YES" && <p>Comapny Profile</p>}

          <Card className='p-4'>
            <Card.Body className='font-medium'>
              {this.props.billinguser.active !== "YES" && <div >
                <Row>
                  <Col>
                    <Form.Label>Comapny Name <span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control type="text" placeholder="Enter Full Name" required  defaultValue={this.state.name}
                      onChange={event => { this.setState({ name: event.target.value }); }} />
                  </Col>
                  <Col>
                    <Form.Label>Comapny Email <span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control type="email" placeholder="Enter email" required defaultValue={this.state.email}
                      onChange={event => { this.setState({ email: event.target.value }); }} />
                  </Col>
                  <Col>
                    <Form.Label>Comapny Phone <span style={{color:"red"}}>*</span></Form.Label>
                    <Form.Control type="text" placeholder="Enter Your Number " required defaultValue={this.state.phone}
                      onChange={this.handleChange} maxLength={"10"} />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control type="text" placeholder="Contact Person"  value={this.state.contactperson}
                      onChange={event => { this.setState({ contactperson: event.target.value }); }} />
                  </Col>
                  <Col>
                    <Form.Label>Designation</Form.Label>
                    <Form.Control type="text" placeholder="Designation"  value={this.state.designation}
                      onChange={event => { this.setState({ designation: event.target.value }); }} />
                  </Col>
                  <Col>
                    <Form.Label>GST NO</Form.Label>
                    <Form.Control type="text" placeholder="Enter Your GST " value={this.state.GST}
                      onChange={event => { this.setState({ GST: event.target.value }); }} />
                  </Col>
                </Row>
                <Row>
                  <br />
                  <Col className='col-md-12'>
                    <label for="exampleFormControlTextarea1" class="form-label">Address</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.address} onChange={event => { this.setState({ address: event.target.value }) }}></textarea>
                  </Col>
                </Row>
                <p className='m-2'><span style={{ color: "red", fontSize: "15px" }}><b>Note:</b></span> Please provide all the information and click on the plan your want to choose .</p>
                <br />
              </div>}

              <section className="pricing-section" style={{ padding: "0" }}>

                <div className="container">
                  <div className="sec-title text-center">
                    <span className="title">Get plan</span>
                  </div>
                  <div>
                    <center> <div style={{ border: "0.5px solid grey", width: "19%", height: "50px", borderRadius: "20px", display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: "-3rem" }}>
                      <Button variant={this.state.monthly == true ? 'success' : 'light'} size='lg' className='m-1' style={{ border: "none", fontSize: "17px", borderRadius: "20px" }} onClick={() => { this.setState({ monthly: true, yearly: false }) }}>Monthly</Button>
                      <Button variant={this.state.yearly == true ? 'success' : 'light'} size='lg' className='m-1' style={{ border: "none", fontSize: "17px", borderRadius: "20px" }} onClick={() => { this.setState({ yearly: true, monthly: false }) }}>Yearly</Button>
                    </div></center>
                  </div>
                  <br />
                  <div className="outer-box">
                  <div className="row">

{this.props.plandetails && this.props.plandetails.results && this.props.plandetails.results.map((data,index)=>
(
    <div className="pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
     <div className="inner-box">
         <div className="icon-box">
         <div className="title text-white">{data.name}</div>
         </div>
          {/* <div className="w-full p-4" onClick={this.onNotification}  style={{backgroundColor: "transparent"}}><div className="d-flex justify-content-center align-items-center w-full font-weight-bold transition hover:opacity-70 radius " style={{backgroundColor: "rgb(1, 189, 111)", color: "rgb(255, 255, 255)",height:"3rem",borderRadius:"5px",fontWeight:700,cursor:"pointer"}}>Get Started</div></div> */}
         <div className="price-box" style={{padding:"20px 14px"}}>
             {/* <div className="title">{this.props.plandetails.results[0].name}</div> */}
             {/* <del className="price">₹100</del> */}
             {/* <span class="flex flex-col"><strong className="mx-2 mt-4" style={{fontWeight:700,fontSize:"30px"}}>{data.monthly_amount}</strong></span>
           <span></span> */}

           {/* {this.state.monthly == true ? <h4 className="price">₹{Math.round(data.monthly_amount/100)}<span style={{color: "rgb(181, 188, 197)",fontSize:"13px",fontWeight:"400"}}>/Month</span></h4>
                 : <h4 className="price">₹{Math.round(data.yearly_amount/100)}<span style={{color: "rgb(181, 188, 197)",fontSize:"13px",fontWeight:"400"}}>/Year</span></h4>
             } */}
             {this.state.monthly ? (
  <h4 className="price">
    ₹
    {data.monthly_amount == 99999
      ? "Unlimited"
      : data.monthly_amount == 11111
      ? "-"
      : Math.round(data.monthly_amount / 100)}
    {data.monthly_amount != 11111 && (
      <span style={{ color: "rgb(181, 188, 197)", fontSize: "15px", fontWeight: "400" }}>
        /Month
      </span>
    )}
  </h4>
) : (
  <h4 className="price">
    ₹
    {data.yearly_amount == 99999
      ? "Unlimited"
      : data.yearly_amount == 11111
      ? "-"
      : Math.round(data.yearly_amount / 100)}
    {data.yearly_amount != 11111 && (
      <span style={{ color: "rgb(181, 188, 197)", fontSize: "15px", fontWeight: "400" }}>
        /Year
      </span>
    )}
  </h4>
)}

         </div>
         <ul className="pricing-list" style={{padding:"0px 16px"}}>
             <li className="true">Forms: <b>{data.form_count == 99999 ?"Unlimited":data.form_count == 11111? "-":data.form_count}</b></li>
             <li className="true">Submission/entries: <b>{data.monthly_submission== 99999 ?"unlimited":data.monthly_submission == 11111? "-":data.monthly_submission}</b></li>
             <li className="true">Field: <b>{data.field_count== 99999 ?"Unlimited":data.field_count == 11111? "-":data.field_count}</b></li>
             <li className="true">Users: <b>{data.user_count== 99999 ?"Unlimited":data.user_count == 11111? "-":data.user_count}</b></li>
             {/* <li className="true">Reports-<b>Unlimited</b></li> */}
             <li className="true">API(Distribution): <b>{data.API_Distribution== 99999 ?"Unlimited":data.API_Distribution == 11111? "-":data.API_Distribution}</b></li>
             <li className="true">Storage: <b>{data.space_assign== 99999 ?"Unlimited":data.space_assign == 11111? "-":data.space_assign}</b></li>
             {/* <li className="true">Project-<b>{this.props.plandetails.results[0].team_count}</b></li> */}
             <li className="true">Teams: <b>{data.team_count== 99999 ?"Unlimited":data.team_count == 11111? "-":data.team_count}</b></li>
             <li className="false">Workflow: <b>{data.workflow_count== 99999 ?"Unlimited":data.workflow_count == 11111? "-":data.workflow_count}</b></li>
             <li className="false">Custom Form: <b>{data.custome_form_count== 99999 ?"Unlimited":data.custome_form_count == 11111? "-":data.custome_form_count}</b></li>
             <li className="false">Custom Logo: <b>{data.Custom_Logo== 99999 ?"Unlimited":data.Custom_Logo == 11111? "-":data.Custom_Logo}</b></li>
            
             <li className="false">Mail Notification: <b>{data.email_count== 99999 ?"Unlimited":data.email_count == 11111? "-":data.email_count}</b></li>
             <li className="false">Field Encryption: <b>{data.Field_Encryption== 99999 ?"Unlimited":data.Field_Encryption == 11111? "-":data.Field_Encryption}</b></li>
             <li className="false">Field Tracking: <b>{data.Field_Tracking== 99999 ?"Unlimited":data.Field_Tracking == 11111? "-":data.Field_Tracking}</b></li>
             
             <li className="false">E-signature: <b>{data.e_signature_count== 99999 ?"Unlimited":data.e_signature_count == 11111? "-":data.e_signature_count}</b></li>
             <li className="false">Form Sharing: <b>{data.form_sharing== 99999 ?"Unlimited":data.form_sharing == 11111? "-":data.form_sharing}</b></li>
             <li className="false">Form Template: <b>{data.form_template== 99999 ?"Unlimited":data.form_template == 11111? "-":data.form_template}</b></li>
             <li className="false">Mobile App: <b>{data.mobile_app== 99999 ?"Unlimited":data.mobile_app == 11111? "-":data.mobile_app}</b></li>
             <li className="false">Customer Support: <b>{data.Customer_Support== 99999 ?"Unlimited":data.Customer_Support == 11111? "-":data.Customer_Support}</b></li>
             {/* <li className="false"> Submission-<b>{this.props.plandetails.results[0].monthly_submission}</b></li> */}
             {/* <li className="false">Space-<b>{this.props.plandetails.results[0].space_assign+"MB"}</b></li> */}

         </ul>
         {/* <div className="btn-box">
             <a className="theme-btn" style={{ textDecoration: "none", cursor: "pointer" }} onClick={this.onNotification}>BUY plan</a>
         </div> */}
     </div>
     {/* <div className="w-full p-4" onClick={this.onNotification}  style={{backgroundColor: "transparent"}}><div className="get-button d-flex justify-content-center align-items-center w-full font-weight-bold transition hover:opacity-70 radius " style={{backgroundColor: "rgb(1, 189, 111)", color: "rgb(255, 255, 255)",height:"3rem",borderRadius:"5px",fontWeight:700,cursor:"pointer"}}>Get Free</div></div> */}
  {/* <div className="btn-box mt-2">
                            <a className="theme-btn" style={{ textDecoration: "none",cursor:"pointer",padding:"1px 30px",borderRadius:"5px" }} onClick={() => { this.onCreateOrder(data.name, this.state.monthly == true ? 30 : 365) }}>BUY plan</a>
                          </div> */}
                           <div className="btn-box mt-2">
      {/* {data.monthly_amount === 11111 && data.yearly_amount === 11111 ? (
        <a className="theme-btn" style={{ textDecoration: "none", cursor: "pointer", padding: "1px 30px", borderRadius: "5px" }} onClick={() => { window.location.href = '/ContactUs'; }}>
          Contact Us
        </a>
      ) : (
        <a className="theme-btn" style={{ textDecoration: "none", cursor: "pointer", padding: "1px 30px", borderRadius: "5px" }} onClick={() => { this.onCreateOrder(data.name, this.state.monthly ? 30 : 365); }}>
          BUY plan
        </a>
      )} */}
      <div className="btn-box mt-2">
  {(data.monthly_amount == 11111 || data.yearly_amount == 11111) ? (
    <a className="theme-btn" style={{ textDecoration: "none", cursor: "pointer", padding: "1px 30px", borderRadius: "5px" }} onClick={() => { window.location.href = '/ContactUs'; }}>
      Contact Us
    </a>
  ) : (
    <a className="theme-btn" style={{ textDecoration: "none", cursor: "pointer", padding: "1px 30px", borderRadius: "5px" }} onClick={() => { this.onCreateOrder(data.name, this.state.monthly ? 30 : 365); }}>
      BUY plan
    </a>
  )}
</div>
    </div>
 </div>
))


}
</div>
                 
                  </div>
                </div>
              </section>
            </Card.Body>
          </Card>

        </div>

      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    token: state.usersReducer.token,
    billinguser: state.billingReducer.billinguser,
    plandetails:state.billingReducer.plandetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createCompany: (acessToken, requestbody) => dispatch(createCompanyProfile(acessToken, requestbody)),
    billingUser: (accessToken) => dispatch(fetchBillingUser(accessToken)),
    UpgradePlan:(acessToken,requestBody2)=>dispatch(UpgradePlan(acessToken,requestBody2)),
    PlanDetails:()=>dispatch(PlanDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingRegister);

import React from "react";
import { useParams } from "react-router-dom";
import { LAYOUT_TYPE } from '../lib/constants';
import ProjectMembers from "../views/project/components/ProjectMembers";
import EditDataDetails from "../views/forms/components/EditDataDetails";
import Share from "../views/forms/components/Share";
import Profile from "../views/users/components/Profile";
import Workflow from "../views/workflows/Components/Workflow";
import WorkflowState from "../views/workflows/Components/WorkflowState";
import WorkflowTransition from "../views/workflows/Components/WorkflowTransition";
import FormMembers from "../views/forms/components/FormMembers";
import CreateUser from "../views/users/components/CreateUser";
import UserLocation from "../views/users/components/UserLocation";
import Green from "../views/users/components/green";
import ExportDocument from "../views/forms/components/ExportDocument";
import LandingPage from "../views/users/components/LandingPage";
import Login from "../views/users/components/Login";
import Register from "../views/users/components/Register";
import SharedPublicForm from "../views/forms/components/SharedPublicForm";
import Dashboard from "../views/common/components/Dashboard";
import Forms from "../views/forms/components/Forms";
import Fields from "../views/forms/components/Fields";
import FormDataEntry from "../views/forms/components/FormDataEntry";
import FormDataView from "../views/forms/components/FormDataView";
import DataDetails from "../views/forms/components/DataDetails";
import Projects from "../views/project/components/Projects";
import ProjectDataView from "../views/project/components/ProjectDataView";
import Organizations from "../views/organisations/components/Organizations";
import FileExplore from "../views/fileexplorer/components/FileExplore";
import OrganizationsDataView from "../views/organisations/components/OrganizationsDataView";
import WorkflowDataView from '../views/workflows/Components/WorkflowDataView';
import WorkflowTemplate from '../views/forms/components/WorkflowTemplates';
import AllReports from '../views/reports/components/AllReports';
import UserReport from '../views/reports/components/UserReport';
import ResetPassword from '../views/users/components/ResetPassword';
import faq from '../views/users/components/faq';
import Logs from '../views/logs/components/Logs';
import TeamDataView from '../views/teamManagement/components/TeamDataView';
import TeamReports from '../views/teamReports/components/TeamReports';
import Video from '../views/users/components/video';
import Notification from '../views/notification/components/Notification';
import CompanyProfile from '../views/Billing/components/CompanyProfile';
import CompanyRegister from '../views/Billing/components/BillingRegister';
import VerifyUser from '../views/users/components/VerifyUser';
import Teams from '../views/teamManagement/components/Teams';
import Pricing from '../views/users/components/Pricing';
import ThankyouPage from "../views/users/components/TankyouPage";
import CustomForms from "../views/customforms/components/CustomFormLayout";
import CustomedForm from "../views/customforms/components/CustomedForm";
import casestudy from "../views/users/components/casestudy";
import Successful from "../views/users/components/Successful";
import Failure from "../views/users/components/Failure";
import Userguide from "../views/users/components/Userguide";
import Refundpolicy from "../views/users/components/Refundpolicy";
import Registers from "../views/users/Registers";
import casestudy1 from "../views/users/components/casestudy1";
import casestudy2 from "../views/users/components/casestudy2";
import casestudy3 from "../views/users/components/casestudy3";
import ContactUs from "../views/users/components/ContactUs";
import Feature from "../views/users/components/Feature";
import PrivacyPolicy from "../views/users/components/PrivacyPolicy";
import TemplateForm from "../views/customforms/components/TemplateForm";
import Group from "../views/groupManagement/components/group"



function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

const routes = [
  { path: '/', exact: true, component: LandingPage, layoutType: LAYOUT_TYPE.NULL },
  { path: '/login', exact: true, component: Login, layoutType: LAYOUT_TYPE.NULL },
  { path: '/thankyou', exact: true, component: ThankyouPage, layoutType: LAYOUT_TYPE.NULL },

  { path: '/login/:url', exact: true, component: Login, layoutType: LAYOUT_TYPE.NULL },
  { path: '/register', exact: true, component: Register, layoutType: LAYOUT_TYPE.NULL },
  { path: '/forms/public/:token', exact: true, component: withParams(SharedPublicForm), layoutType: LAYOUT_TYPE.NULL },
  { path: '/forms/public/:token/:form_id', exact: true, component: withParams(SharedPublicForm), layoutType: LAYOUT_TYPE.NULL },
  { path: '/dashboard', exact: true, component: Dashboard, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/profile', exact: true, component: Profile, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/forms', exact: true, component: Forms, layoutType: LAYOUT_TYPE.BASELAYOUT },

  { path: '/customforms/:id', exact: true, component: withParams(CustomForms), layoutType: LAYOUT_TYPE.NULL },
  { path: '/customforms/', exact: true, component: CustomForms, layoutType: LAYOUT_TYPE.NULL },
  { path: '/customedform/:id', exact: true, component: withParams(CustomedForm), layoutType: LAYOUT_TYPE.NULL },

  { path: '/forms/:id/fields', exact: true, component: withParams(Fields), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/forms/:id/add_data', exact: true, component: withParams(FormDataEntry), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/forms/:id/view_data', exact: true, component: withParams(FormDataView), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/forms/:template_id/DataDetails/:id', exact: true, component: withParams(DataDetails), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/forms/:template_id/EditDataDetails/:id', exact: true, component: withParams(EditDataDetails), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/workflow', exact: true, component: Workflow, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/workflow/:id/state', exact: true, component: withParams(WorkflowState), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/workflow/:id/transition', exact: true, component: withParams(WorkflowTransition), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/forms/template/:id/share', exact: true, component: withParams(Share), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/projects', exact: true, component: Projects, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/projects/:id/view_data', exact: true, component: withParams(ProjectDataView), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/project/:id/members', exact: true, component: withParams(ProjectMembers), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/organizations', exact: true, component: Organizations, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/fileexplorer', exact: true, component: FileExplore, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/forms/:id/view', exact: true, component: withParams(Forms), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/organizations/:id', exact: true, component: withParams(OrganizationsDataView), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/forms/:id/members', exact: true, component: withParams(FormMembers), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/workflow/:id/view_data', exact: true, component: withParams(WorkflowDataView), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/forms/:id/view_workflow', exact: true, component: withParams(WorkflowTemplate), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/createUser', exact: true, component: withParams(CreateUser), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/userlocation/:id', exact: true, component: withParams(UserLocation), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/allReports', exact: true, component: AllReports, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/userReport', exact: true, component: UserReport, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/:token/resetPassword', exact: true, component: ResetPassword, layoutType: LAYOUT_TYPE.NULL },
  { path: '/faq', exact: true, component: faq, layoutType: LAYOUT_TYPE.NULL },
  { path: '/resetPassword', exact: true, component: ResetPassword, layoutType: LAYOUT_TYPE.NULL },
  { path: '/logs', exact: true, component: Logs, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/teams', exact: true, component: Teams, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/teams/:id/view_data', exact: true, component: withParams(TeamDataView), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/teamReports', exact: true, component: withParams(TeamReports), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/groups', exact: true, component: Group, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/video', exact: true, component: Video, layoutType: LAYOUT_TYPE.NULL },
  { path: '/green', exact: true, component: Green, layoutType: LAYOUT_TYPE.NULL },
  { path: '/notification', exact: true, component: Notification, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/companyprofile', exact: true, component: CompanyProfile, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/companyRegister', exact: true, component: CompanyRegister, layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/Pricing', exact: true, component: Pricing, layoutType: LAYOUT_TYPE.NULL },
  { path: '/VerifyUser', exact: true, component: VerifyUser, layoutType: LAYOUT_TYPE.NULL },
  { path: '/forms/:template_id/ExportDocument/:id', exact: true, component: withParams(ExportDocument), layoutType: LAYOUT_TYPE.BASELAYOUT },
  { path: '/casestudy', exact: true, component: casestudy, layoutType: LAYOUT_TYPE.NULL },
  { path: '/successful', exact: true, component: Successful, layoutType: LAYOUT_TYPE.NULL },
  { path: '/failure', exact: true, component: Failure, layoutType: LAYOUT_TYPE.NULL },
  { path: '/subscription', exact: true, component: CompanyRegister, layoutType: LAYOUT_TYPE.NULL },
  { path: '/userguide', exact: true, component: Userguide, layoutType: LAYOUT_TYPE.NULL },
  { path: '/refundpolicy', exact: true, component: Refundpolicy, layoutType: LAYOUT_TYPE.NULL },
  { path: '/registers', exact: true, component: Registers, layoutType: LAYOUT_TYPE.NULL },

  { path: '/casestudy1', exact: true, component: casestudy1, layoutType: LAYOUT_TYPE.NULL },
  { path: '/casestudy2', exact: true, component: casestudy2, layoutType: LAYOUT_TYPE.NULL },
  { path: '/casestudy3', exact: true, component: casestudy3, layoutType: LAYOUT_TYPE.NULL },
  { path: '/ContactUs', exact: true, component: ContactUs, layoutType: LAYOUT_TYPE.NULL },
  { path: '/Feature', exact: true, component: Feature, layoutType: LAYOUT_TYPE.NULL },
  { path: '/PrivacyPolicy', exact: true, component: PrivacyPolicy, layoutType: LAYOUT_TYPE.NULL },

  { path: '/templateform/', exact: true, component: TemplateForm, layoutType: LAYOUT_TYPE.NULL },
  { path: '/templateform/:id', exact: true, component: withParams(TemplateForm), layoutType: LAYOUT_TYPE.NULL },








]

export default routes;

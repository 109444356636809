import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, Button, Table, Stack, Form } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { fetchFields, fetchFormData, fetchForms, deleteFormData, fetchFormEntries, fetchDownloadData, fetchComments, deleteForm, fetchFormMembers, searchTemplateData, advanceSearch } from '../action';
import { arrangeFormData, formateDate, getFieldLabelArray, getAutoSumField, arrangeAutoSum } from '../../../lib/utils';
import { fetchProjectDetails } from '../../project/action';
import DeleteModal from '../../common/components/DeleteModal';
import FormCommentModal from './FormCommentModal';
import FileTypeFieldHandler from './fields/FileTypeFieldHandler';
import AddWorkflowModal from "./AddWorkflowModal"
import AssignWorkflowModal from './AssignWorkflowModal';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import { Link, Navigate } from 'react-router-dom';
import ConfigureWorkflowModal from './ConfigureWorkflowModal';


class FormDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedData: {},
            showDeleteModal: false,
            autoSumFields: [],
            arrangedAutoSum: [],
            showWorkflowModal: false,
            assignWorkflowModal: false,
            configureWorkflowModal: false,
            selectType: "",
            searchContent: "",
            navigation: "",
            advancesearch: false,
            advanceSearchValue: ""
        }
    }

    componentDidMount() {
        this.props.getFields(this.props.token.access, this.props.params.id);
        this.props.getFormData(this.props.token.access, this.props.params.id);
        this.props.getForm(this.props.token.access, this.props.params.id);
        this.props.getFormEntries(this.props.token.access, this.props.params.id);
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.form.project !== null && prevProps.form.project !== this.props.form.project) {
            this.props.fetchProjectDetails(this.props.token.access, this.props.form.project);
        }
        if (this.props.fields.length > 0) {
            this.state.autoSumFields = getAutoSumField(this.props.fields)
        }
        if (this.props.fields && this.props.fields.length > 0 && this.props.formData && this.props.formData.data && this.props.formData.data.length > 0) {
            this.state.arrangedAutoSum = arrangeAutoSum(this.state.autoSumFields, this.props.formData.data)
        }
    }

    deleteFormData(data) {
        this.props.deleteFormData(this.props.token.access, data.id, this.props.params.id);
    }
    onSearchClick = (e) => {
        e.preventDefault()
        this.props.searchTemplateData(this.props.token.access, this.props.params.id, this.state.selectType, this.state.searchContent);
        this.setState({ selectType: "", searchContent: "" })

    }
    onAdvanceSearchClick = (e) => {
        e.preventDefault()
        this.props.advanceSearch(this.props.token.access, this.props.params.id, this.state.advanceSearchValue);
        this.setState({ advanceSearchValue: "" })
    }

    render() {
       
        return (
            <div className="main-content-container p-4 container-fluid">
                {
                    this.state.navigation &&
                    <Navigate to={this.state.navigation} />
                }
                <div className='mb-3'>
                    <Breadcrumbs name={this.props} disabledStrings={[this.props.params.id]} />
                </div>
                <Row>
                    <Col>
                        <Row>
                            <Col md="5">
                                <h3>{this.props.form.label}</h3>
                                {
                                    ![undefined, null].includes(this.props.form.project) &&
                                    <h3 style={{ fontSize: 11 }}>Project: {this.props.projectDetails.name}</h3>
                                }
                                <p>{this.props.form.description}</p>
                            </Col>
                            <Col md='7' className='d-flex justify-content-end align-items-center'>
                                {
                                    localStorage.getItem('key') === 'admin' && this.props.form && this.props.form.workflow === null ?
                                        <Button className="m-1" onClick={() => this.setState({ showWorkflowModal: true })} variant="outline-primary" size='md'>ADD WORKFLOW</Button>
                                        : null
                                }
                                {
                                    localStorage.getItem('key') === 'admin' && this.props.form && this.props.form.workflow !== null ?
                                        <><Button onClick={() => this.setState({ assignWorkflowModal: true })} variant='primary'>Assign Workflow</Button> 
                                        <Button onClick={() => this.setState({ configureWorkflowModal: true })} variant='secondary'>Configure Workflow</Button>
                                        </>
                                        : null
                                }

                                {/* {
                                    localStorage.getItem('key') === 'admin' && this.props.form && this.props.form.workflow !== null ?
                                        <Button variant='danger'>Delete Workflow</Button> : null
                                } */}

                                {/* <Link to={`/forms/${this.props.params.id}/add_data`}> */}
                                {
                                    this.props.formData?.current_user?.level != "REPORTER" && 
                               
                                <Button onClick={() => { this.setState({ navigation: this.props.form.type == 'C' ? `/customedform/${this.props.form.id}` : `/forms/${this.props.params.id}/add_data` }) }} className="m-1" variant="outline-primary" size='md'>ADD DATA</Button>
                            }
                                {/* </Link> */}

                                <Button variant='outline-info'
                                    onClick={() => this.props.exportData(this.props.token.access, this.props.params.id, this.props.form.label)}>
                                    EXPORT
                                </Button>
                                <Link to={`/forms/template/${this.props.params.id}/share`}>
                                    <Button className="m-1" variant="outline-success">SHARE</Button>
                                </Link>
                            </Col>
                        </Row>

                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                            <Card className='container' style={{ marginBlock: 20, borderTop: "2px solid white" }} >
                                {this.state.advancesearch == true ?
                                    <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 8 }}>

                                        <Col xs="6">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Advance Search</Form.Label>
                                                <Form.Control type="text" value={this.state.advanceSearchValue
                                                } onChange={(e) => { this.setState({ advanceSearchValue: e.target.value }) }} />

                                            </Form.Group>

                                        </Col>
                                        <Col xs="3">
                                            <button type="button" className="btn btn-success" style={{ width: "100%", marginTop: 12 }} onClick={(e) => { this.onAdvanceSearchClick(e) }}>Search</button>
                                        </Col>
                                        <Col xs="3">
                                            <button type="button" className="btn btn-primary" style={{ width: "100%", marginTop: 12 }} onClick={(e) => {
                                                this.setState({ advancesearch: !this.state.advancesearch })
                                            }}>Normal<i class="fa fa-search" aria-hidden="true" style={{ marginLeft: "1em" }}></i></button>
                                        </Col>
                                    </Row> : <>
                                        <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 8 }}>

                                            <Col xs="4">
                                                <Form.Label>Select Type</Form.Label>
                                                <Form.Select aria-label="Default select example" style={{ width: "100%", padding: "0.375rem 0.75rem", border: '1px solid #ced4da', color: '#495057', marginBottom: "15px" }} onChange={event => { this.setState({ selectType: event.target.value }); }}>
                                                    {this.props.fields && this.props.fields.map((item, index) => {
                                                        if (item.type !== "OCR_VIEW") {
                                                            return (

                                                                <option>Select Type</option>,
                                                                <option value={item.id}>{item.label}</option>
                                                            )
                                                        }

                                                    })}
                                                </Form.Select>
                                            </Col>
                                            <Col xs="4">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Search</Form.Label>
                                                    <Form.Control type="text" value={this.state.searchContent
                                                    } onChange={event => { this.setState({ searchContent: event.target.value }); }} />

                                                </Form.Group>

                                            </Col>
                                            <Col xs="2">
                                                <button type="button" className="btn btn-success" style={{ width: "100%", marginTop: 12 }} onClick={(e) => { this.onSearchClick(e) }}>Search</button>
                                            </Col>
                                            <Col xs="2">
                                                <button type="button" className="btn btn-primary" style={{ width: "100%", marginTop: 12 }} onClick={(e) => {
                                                    this.setState({ advancesearch: !this.state.advancesearch })
                                                }}>Advance<i class="fa fa-search" aria-hidden="true" style={{ marginLeft: "1em" }}></i></button>
                                            </Col>
                                        </Row>
                                    </>
                                }

                            </Card>
                        </div>
                        {
                            this.props.formData && this.props.formData.data !== undefined && this.props.formData.data.length > 0 &&
                            <div>
                                <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                    <Card className='container p-3' style={{ overflowY: "scroll" }}>
                                        <DataTable
                                            filterised={true}
                                            totalItemsCount={this.props.formData.count}
                                            onPageChange={(pageNumber) => this.props.getFormData(this.props.token.access, this.props.params.id, pageNumber)}
                                            headers={getFieldLabelArray(this.props.fields)}
                                            body={
                                                this.props.fields && this.props.formData.data.length > 0 && this.props.formData.data.map((row, index) => {
                                                    if (row.type !== "OCR_VIEW") {
                                                        let data = arrangeFormData(this.props.fields, row.data);
                                                        data.push(formateDate(row.date_updated))
                                                        data.push(row.user === null ? 'Anonymous' : row.user.name)
                                                        data.push(
                                                            <Row style={{ display:"flex",justifyContent:"space-between" }}>
                                                                <Col md='4' className='mt-1 mb-1'>
                                                                    <Link to={`/forms/${row.template}/DataDetails/${row.id}`}>
                                                                        <Button title='View' variant="success" size='sm'><i className="fa fa-eye" style={{ fontSize: "15px", color: "white"}} /></Button>
                                                                    </Link>
                                                                </Col>
                                                                {
                                                                    this.props.formData?.current_user?.level == "OWNER"?<Col md='4' className='mt-1 mb-1'>
                                                                    <Link to={`/forms/${row.template}/ExportDocument/${row.id}`}>
                                                                        <Button title='Convert' variant="primary" size='sm'><i class="fa fa-refresh" aria-hidden="true" style={{ fontSize: "15px", color: "white"}}></i></Button>
                                                                    </Link>
                                                                </Col>:null
                                                                }

                                                                {
    (this.props.formData?.current_user?.level == "OWNER" || 
    (this.props.formData?.current_user?.level == "INDIVIDUAL" && 
    row.lock_status === "N" )) ? (
        <Col md="4" className="mt-1 mb-1">
            <Button 
                title="Delete" 
                onClick={() => { this.setState({ showDeleteModal: true, selectedData: row }) }} 
                variant="danger" 
                size="sm">
                <i className="fa fa-trash-o" aria-hidden="true" style={{ fontSize: "15px", color: "white" }}></i>
            </Button>
        </Col>
    ) : null
}


                                                               
                                                            </Row>
                                                        )
                                                        return data;
                                                    }
                                                })
                                            }
                                        />
                                    </Card>
                                    <DeleteModal modalText={"Delete Data ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                        deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteFormData(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                    />
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
                {
                    this.props.form && this.props.form.workflow !== null ?
                        <AssignWorkflowModal showModal={this.state.assignWorkflowModal} handleClose={() => this.setState({ assignWorkflowModal: false })} formId={this.props.params.id} workflowId={this.props.form.workflow} /> : <AddWorkflowModal showModal={this.state.showWorkflowModal} handleClose={() => this.setState({ showWorkflowModal: false })} templateId={this.props.params.id} />
              
              }
                  {
                    this.props.form && this.props.form.workflow !== null ?
                        <ConfigureWorkflowModal showModal={this.state.configureWorkflowModal} handleClose={() => this.setState({ configureWorkflowModal: false })} formId={this.props.params.id} workflowId={this.props.form.workflow} /> 
                        : null
              
              }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fields: state.formsReducer.fields,
        formData: state.formsReducer.formData,
        formEntryData: state.formsReducer.formEntryData,
        form: state.formsReducer.form,
        token: state.usersReducer.token,
        projectDetails: state.projectReducer.projectDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        getFormData: (accessToken, templateId, pageNumber) => dispatch(fetchFormData(accessToken, templateId, pageNumber)),
        getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
        exportData: (accessToken, templateId, templateLabel) => dispatch(fetchDownloadData(accessToken, templateId, templateLabel)),
        getFormEntries: (accessToken, templateId) => dispatch(fetchFormEntries(accessToken, templateId)),
        fetchProjectDetails: (accessToken, projectId) => dispatch(fetchProjectDetails(accessToken, projectId)),
        deleteFormData: (accessToken, dataId, formId) => dispatch(deleteFormData(accessToken, dataId, formId)),
        searchTemplateData: (accessToken, templateId, fieldId, Searchcontent) => dispatch(searchTemplateData(accessToken, templateId, fieldId, Searchcontent)),
        advanceSearch: (accessToken, templateId, advanceSearchValue) => dispatch(advanceSearch(accessToken, templateId, advanceSearchValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDataView);




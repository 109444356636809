import React, { Component } from 'react';
import { Button, Card } from 'react-bootstrap';
import Navbar from "./Navbr";
import Refund from '../../../Images/Refund.png'
import html2pdf from 'html2pdf.js';



export default class Refundpolicy extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    handleExportPDF = () => {
        const element = document.getElementById('jeev');

        if (element) {
            html2pdf().from(element).save('exported.pdf');
        }
    };

    render() {
        return (
            <div>
                <Navbar />
                {/* <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <Card className='p-4 m-3' style={{ width: "70%" }}>
                        <span> <i class="fa fa-file-pdf-o" aria-hidden="true" title='Download As PDF' style={{ fontSize: "25px", color: "red", float: "right" }} onClick={() => { this.handleExportPDF() }}></i></span>
                        <div style={{ overflowY: "scroll", height: "700px" }} id="jeev">

                            <center><h5>RefundPolicy</h5>
                                <hr style={{ width: "50%" }}></hr></center>
                            <center><img src={Refund} style={{ height: "400px" }} alt='img'></img></center>
                            <p className='text-justify'>At Giga Forms we strive to create quality software that you enjoy using for your business or professional life. You have a number of choices and we appreciate you giving us your business. Thank You. We have created this policy that details what we will do should we fail to meet your expectations.</p>
                            <p><b>Monthly Subscriptions:</b>
                                <br />
                                If at anytime during your first month using our service you are dissatisfied, please contact us. We will do our best to address your issue, provide a work around or give a timeline for a solution that will meet your needs. If you are not satisfied, we will gladly offer you a FULL REFUND for your purchase, and downgrade your account to the free plan for that service.</p>
                            <p><b>Annual Subscriptions:</b>
                                <br />

                                We doesn't force you into an annual subscription as a condition to use our services. We prefer to give you the flexibility to choose. In exchange for you signing up for an annual up-front commitment, we offer you a significant discount over the already-low monthly subscription cost. If at anytime during your first 45 days using our service you are dissatisfied, please contact us. We will do our best to address your issue, provide a work around or give a timeline for a solution that will meet your needs. If you are not satisfied, we will gladly offer you a FULL REFUND for your purchase, and downgrade your account to the free plan.</p>
                            <p>We want you to be happy with our service throughout your entire contract, not only the first 30 days (in case of monthly subscription) and the first 45 days (in case of annual subscription). So we go beyond that. If at anytime during your contract we remove, break or discontinue functionality that was available at the time you signed up for our services , we ask you to notify us immediately. If we fail to address the breakage of a functionality within any promised timelines , or if we are unable to provide a satisfactory work around for discontinued functionality, we will offer you a PRO-RATED REFUND for the reminder of your contract.</p>
                            <p><b>Partial Downgrade of Annual Subscription:</b>
                                <br />
                                There may be situations where you wish to partially downgrade your annual subscription. Partial downgrade occurs if, during your annual subscription period, you reduce the number of user licenses, downgrade the edition of our services, downgrade your paid support plan or remove an add-on that you had previously purchased. In case of partial downgrade of annual subscription, you will be provided credits in the form of extension of subscription for the active licenses. The discounts that were applied will be excluded for calculating the quantum of credits, i.e., the value for the un-used license = total amount paid - (monthly list price x number of months used).</p>
                            <p className='text-left'><b>Auto-Renewal:</b>
                                <br />
                                Every time before your subscription expiries, we will send a mail specifying the amount that will be charged to your renew. Similarly, after each renewal we will send you a receipt via e-mail specifying the amount that has been deducted together with the next renewal date and the next renewal amount.</p>
                            <p>We know that sometimes customers forget to cancel an account they no longer want until it is has already been charged. That's why you can cancel your monthly/annual subscription even five business days after your renewal date, and we will still process your cancellation and give you a PARTIAL REFUND .</p>
                            <center><p>For questions, please e-mail<span style={{ paddingLeft: "10px", color: "red" }}><a href="mailto:upport@gigaforms.com">support@gigaforms.com
                            </a></span> </p></center>
                        </div>

                    </Card>
                </div> */}
 <div class="row" >
                <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 ">
                <div className="sec-title text-center" style={{margin:"30px 0"}}>
              <h2>Return and Refund Policy</h2></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="container" >
        
                   
                  
                    <p>Thank you for using GigaForms. We value your satisfaction and strive to provide the best service. Please review our refund policy for further details.
                        
                        </p>
                        <h3  >Interpretation and Definitions</h3>
                        <div class="container" style={{padding: "10px 0px 0 40px"}}>
        
                       
                        <ul>
                            <li style={{listStyle: "disc"}}>
                                <span style={{fontSize:"18px",fontWeight:600}}>Company :</span>
                                <span>refers to Epsum Labs Private Limited.

    </span>
                            </li>
                            <li style={{listStyle: "disc"}}>
                                <span style={{fontSize:"18px",fontWeight:600}}>Goods :</span>
                                <span > refer to the subscription plans offered.
        </span>
                            </li>
                            <li style={{listStyle: "disc"}}>
                                <span style={{fontSize:"18px",fontWeight:600}}>Service :</span>
                                <span >refers to the GigaForms platform (https://gigaforms.in).
        </span>
                            </li>
                            <li style={{listStyle: "disc"}}>
                                <span style={{fontSize:"18px",fontWeight:600}}>You :</span>
                                <span >refers to the individual or entity accessing the Service.
        </span>
                            </li>
                           
                        </ul>
                    </div>
                  

                {/* <h3  style={{marginTop: "10px"}}>Interpretation and Definitions</h3>
                <div class="container" style={{padding: "10px 0px 0 40px"}}>
        
                       
                    <ul>
                        <li style={{listStyle: "disc"}}>
                            
                            <span>**Company** refers to Epsum Labs Private Limited.

</span>
                        </li>
                        <li style={{listStyle: "disc"}}>
                            
                            <span > **Goods** refer to the subscription plans offered.
    </span>
                        </li>
                        <li style={{listStyle: "disc"}}>
                            
                            <span > **Service** refers to the GigaForms platform (https://gigaforms.in).
    </span>
                        </li>
                        <li style={{listStyle: "disc"}}>
                            
                            <span > **You** refers to the individual or entity accessing the Service.
    </span>
                        </li>
                       
                    </ul>
                </div> */}
                <h3  style={{marginTop: "10px"}}>Subscription Cancellation Rights</h3>
                <p>
                You may cancel your subscription within 30 days of purchase. Refunds are applicable only once per user. Cancellations after this period will not be eligible for a full refund.

                </p>
                <h3  style={{marginTop: "10px"}}>Customizations and Non-Refundable Services</h3>
                <p>
                Custom integrations, personalized forms, or tailored services are non-refundable. Partial refunds may be issued based on service usage.

                </p>
                <h3  style={{marginTop: "10px"}}>Returning Services</h3>
                <p>
                Since GigaForms is a digital service, no physical returns apply. Please contact us for subscription cancellations.

                </p>
                <h3  style={{marginTop: "10px"}}>Refund Processing</h3>
                <p>
                Refunds will be processed within 14 days of cancellation approval. The same payment method will be used for reimbursement.

                </p>
                <p>If you have any queries regarding our refund policy , please
                    contact us at:</p>
                    {/* <p>Phone: (+91)7377073774</p> */}
                    <p>Email: info@epsumlabs.com.</p>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

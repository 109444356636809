import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap'
import { fetchActiveTemplateForm, fetchFormTemplateAllCategory, fetchFormTemplateByCategory } from '../../forms/action';
import { connect } from 'react-redux';
import { full_field_details, getFields, } from '../FieldTypes';
const styles = {
    card_details: {
        paddingTop: "5%",
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
        fontFamily: "serif",
        borderBottomStyle: "dashed",
        borderBottomWidth: 1,
        display: "flex",
        justifyContent: "space-evenly"
    },
};
function TemplateFormModal(props) {
    console.log(props,'props')
    const [allFieldDetails, setAllFieldDetails] = useState(props.templateForm);
    const [formEditStatus, setFormEditStatus] = useState(false);
    const [showFormEditModal, setShowFormEditModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [formTemplate, setFormTemplate] = useState([]);
    const [category, setCategory] = useState('all');
        // For template data
    const[tempAllField, settempAllField] = useState()


    const handleCategory = (e) => {
        const categoryValue = e.target.value;
        setCategory(categoryValue);
        if (categoryValue) {
            props.fetchFormTemplateByCategory(props.token.access, categoryValue, setFormTemplate);
        }
       
    };
    useEffect(() => {
        if (props.templateForm) {
            setAllFieldDetails(props.templateForm);
        }
    }, [props.templateForm]);
    useEffect(() => {
        if (props.token?.access) {
            props.fetchFormTemplateAllCategory(props.token.access);
            props.fetchFormTemplateByCategory(props.token.access, category, setFormTemplate);
           
        }
    }, [props.token, category]);
    useEffect(() => {
        setAllFieldDetails(props.templateForm);
    }, [props.token]);

useEffect(() => {
       
    if (props.token?.access) {
        setAllFieldDetails(props.templateform);
    }
}, []);
useEffect(() => {
    setSelectedCategory(props.allCategory?.data || []);
}, [props.allCategory]);
console.log(props.templateForm,'t')
    return (
        <Modal show={props.TemplateFormStatus} onHide={()=>props.setTemplateFormStatus(false)} centered size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Template Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row style={{ height: "100%" }}>
                <Col className='my-scrollbar' md="12" style={{ height: "100%", overflowY: "scroll" }}
                    onDragOver={props.allowDrop}
                    onDrop={(e) => { if (allFieldDetails?.data?.length === 0) props.drop(e); }}
                >
                    <div onMouseEnter={() => setFormEditStatus(true)} onMouseLeave={() => setFormEditStatus(false)} style={{ backgroundColor: "#fff", borderRadius: 5 }}>
                        {/* <div style={{ ...styles.card_details, backgroundImage: `url(${props.formDetails.image_header})` }} title='Form Details'>
                            <div style={{ width: "85%" }}>
                                <h4 style={{ textAlign: "left", fontWeight: "bolder" }}>Template Form</h4>
                            </div>
                        </div> */}
                        {/* {allFieldDetails?.data?.length === 0 ? (
                            <div style={{ paddingBlock: "5%" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderStyle: "dashed", borderRadius: 15, marginInline: "5%", paddingBlock: 30 }}>
                                    Template Not Available
                                </div>
                            </div>
                        ) 
                        :( */}
                            <div>
                                <Row style={{ display: "flex", width: "100%",padding:"10px"}}>
                                    <Col md={4}>
                                        <Form.Group className="mb-2" controlId="formGridCategory">
                                            <Form.Label>Select A Category</Form.Label>
                                            <Form.Select onChange={handleCategory} value={category}>
                                                <option value="">Select Category</option>
                                                <option value="all">All</option>
                                                {selectedCategory.map((item, index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{ display: "flex", width: "100%",padding:"10px" }}>
                                    {formTemplate?.data?.map((item, index) => {
                                        const dataform = item.custom_form_data;
                                        return (
                                            <Col key={index} md={3} style={{ marginBottom: "3rem" }}>
                                                <div style={{ background: "#e3e5f5", overflow: "hidden", height: "200px", position: "relative" }}>
                                                    <img src='https://files.jotform.com/jotformapps/new-customer-registration-form-e695b96ddb999f8e5877db26805f1cb7-scrollable.png' alt='img' style={{ padding: "10px" }} />
                                                    <div className='template-shadow'></div>
                                                </div>
                                                <div className='template-header'><h3>{dataform.label}</h3></div>
                                                <div className='template-btn' onClick={()=>{props.activeTemplate(item.form_id)}}>Use Template</div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        {/* )} */}
                    </div>
                  
                </Col>
          
            
        </Row>
              </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary" onClick={()=>props.setTemplateFormStatus(false)}>
                    Close
                </Button>
                <Button variant="success" onClick={()=>props.setTemplateFormStatus(false)}>
                    Create
                </Button>
            </Modal.Footer> */}
        </Modal>
    )
}


const mapStateToProps = state => ({
    token: state.usersReducer.token,
    allCategory: state.formsReducer.allCategory,
    templateForm: state.formsReducer.templateForm,
    templateAllField:state.formsReducer.templateAllField,
});

const mapDispatchToProps = dispatch => ({
    fetchFormTemplateAllCategory: (accessToken) => dispatch(fetchFormTemplateAllCategory(accessToken)),
    fetchFormTemplateByCategory: (accessToken, category, callback) => dispatch(fetchFormTemplateByCategory(accessToken, category, callback)),
    
    fetchActiveTemplateForm: (accessToken, form_id, callback) => dispatch(fetchActiveTemplateForm(accessToken, form_id, callback)),
    // fetchTemplateAllFields: (accessToken, form_id) => dispatch(fetchTemplateAllFields(accessToken, form_id)),
});
export default connect(mapStateToProps, mapDispatchToProps) (TemplateFormModal);
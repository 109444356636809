import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD, BASE_URL, RESPONSE_TYPE } from "../../lib/constants";
import { FETCH_ALL_GROUP, FETCH_TEAM, FETCH_TEAM_MEMBERS } from "./actionTypes";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";

export function fetchAllGroups(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/forms/notification-groups/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                console.log(successResponse,'successResponse')
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_ALL_GROUP, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function createGroup(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/notification-groups/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, "Group Created successfully", "success"));
                dispatch(fetchAllGroups(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}
export function deleteGroup(accessToken, groupid) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/notification-groups/${groupid}`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Groups deleted Successfully', 'danger'))
                dispatch(fetchAllGroups(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}
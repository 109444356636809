import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createGroup, deleteGroup, fetchAllGroups } from '../action';
import DataTable from '../../common/components/DataTable';
import DeleteModal from '../../common/components/DeleteModal';

const Group = (props) => {
    const [groupName, setGroupName] = useState('');
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [selectedData, setselectedData] = useState(null); // Initialize as null or empty object

    // Handle form submit
    const handleCreateAction = (e) => {
        e.preventDefault();
        const { token, createGroup } = props;

        // Ensure token exists before proceeding
        if (token && token.access) {
            createGroup(token.access, { group_name: groupName });
            setGroupName('');  // Reset the form after submission
        } else {
            console.error('Token is missing or invalid');
        }
    };

    useEffect(() => {
        if (props.token?.access) {
            props.fetchAllGroups(props.token.access);
        }
    }, [props.token?.access, props.fetchAllGroups]);

    const deleteAction = (event) => {
        props.deleteAction(props.token.access, event.id);
        setselectedData(null); // Reset selectedData after deletion
    };

    console.log(selectedData, 'groups');
    return (
        <div className="main-content-container p-4 container-fluid">
            <Row>
                <Col>
                    <p>Group Management</p>
                    <Card>
                        <Card.Body className="font-medium">
                            <Form onSubmit={handleCreateAction}>
                                <Row>
                                    <Col className="m-4">
                                        <Form.Label>
                                            Group Name <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            placeholder="Enter Group Name"
                                            required
                                            value={groupName}
                                            onChange={(e) => setGroupName(e.target.value)}
                                        />
                                    </Col>

                                    <Col className="m-5">
                                        <Button type="submit" variant="outline-success" size="sm">
                                            CREATE
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>

                            <div className="mt-3 font-medium">
                                {props.groups.results && props.groups.results.length > 0 && (
                                    <DataTable
                                        totalItemsCount={props.groups.count}
                                        headers={['Group Name', 'Action']}
                                        body={props.groups.results.map((row, index) => {
                                            return [
                                                row.group_name,
                                                <Row key={index}>
                                                    <Col mr="6">
                                                        <Button
                                                            title="Delete"
                                                            className="m-1"
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => {
                                                                setshowDeleteModal(true);
                                                                setselectedData(row); // Set selected data correctly
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-trash-o"
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'white',
                                                                    padding: '2px 3px',
                                                                }}
                                                            ></i>
                                                        </Button>
                                                    </Col>
                                                </Row>,
                                            ];
                                        })}
                                    />
                                )}
                            </div>

                            <DeleteModal
                                modalText="Delete Group ?"
                                showModal={showDeleteModal}
                                handleClose={() => setshowDeleteModal(false)}
                                deleteAction={(status) => {
                                    if (status !== undefined && status === true) {
                                        deleteAction(selectedData); // Pass selectedData to delete
                                    }
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
    groups: state.groupReducer.groups,
});

const mapDispatchToProps = (dispatch) => ({
    createGroup: (accessToken, requestBody) => dispatch(createGroup(accessToken, requestBody)),
    fetchAllGroups: (accesstoken) => dispatch(fetchAllGroups(accesstoken)),
    deleteAction: (accessToken, groupid) => dispatch(deleteGroup(accessToken, groupid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Group);

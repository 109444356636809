import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, Stack } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { fetchFormsList, fetchFields, fetchFormData, fetchAllFormsList } from '../../forms/action';
import { fetchAllReports, fetchAllReportsFilter, fetchTemplateUser } from '../action';
import { formateDate, getReportFieldLabelArray, arrangeFormData, getAutoSumField, arrangeAutoSum, handleExcelData, exportToCSV, arrangeFormDataReportTable, getReportFieldLabelArrayTable, getReportFieldIdArrayTable } from '../../../lib/utils';
import DeleteModal from '../../common/components/DeleteModal';

class AllReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeleteModal: false,
            selectedData: {},
            templateId: '',
            startDate: '',
            endDate: '',
            user: '',
            templateName: '',
            autoSumFields: [],
            arrangedAutoSum: {},
            excelExportData: [],
            event: {}
        }
    }

    componentDidMount() {
        this.props.getFormList(this.props.token.access);
        this.props.fetchAllFormsList(this.props.token.access);
    }
     fetchquarydata=(quaryvalue)=>{
        var requestBody = ``
        if (this.state.startDate && this.state.endDate) {
            requestBody = `startdate=${this.state.startDate}&enddate=${this.state.endDate}`
        }
        if (this.state.user) {
            requestBody = `user=${this.state.user}`
        }
        if (this.state.startDate && this.state.endDate && this.state.user) {
            requestBody = `startdate=${this.state.startDate}&enddate=${this.state.endDate}&user=${this.state.user}`
        }
        this.props.getReportDatafilter(this.props.token.access,this.state.templateId,requestBody,quaryvalue);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.fields.length > 0) {
            this.state.autoSumFields = getAutoSumField(this.props.fields)
        }
        if (this.props.fields && this.props.fields.length > 0 && this.props.formData && this.props.formData.data && this.props.formData.data.length > 0) {
            this.state.arrangedAutoSum = arrangeAutoSum(this.state.autoSumFields, this.props.formData.data)
        }
        if (prevState.templateId !== this.state.templateId) {
            this.props.fetchTemplateUser(this.props.token.access, this.state.templateId)
        }
    }

    handleSubmitAction = (event, pageNumber = 1) => {
        this.setState({ event: event })
        event.preventDefault()
        var requestBody = ``
        this.props.getFields(this.props.token.access, this.state.templateId);
        this.props.getFormData(this.props.token.access, this.state.templateId);

        if (this.state.startDate && this.state.endDate) {
            requestBody = `?startdate=${this.state.startDate}&enddate=${this.state.endDate}&page=${pageNumber}`
        }
        if (this.state.user) {
            requestBody = `?user=${this.state.user}&page=${pageNumber}`
        }
        if (this.state.startDate && this.state.endDate && this.state.user) {
            requestBody = `?startdate=${this.state.startDate}&enddate=${this.state.endDate}&user=${this.state.user}&page=${pageNumber}`
        }
        this.props.getReportData(this.props.token.access, this.state.templateId, requestBody);

    }

    render() {
        console.log( this.props.allreports,'reports')
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <h6>All Reports</h6>
                            </Col>
                            <Col md="2">
                                <Button variant='success' style={{ width: "100%" }} onClick={() => {
                                    var excelExportData = this.props.allreports && this.props.allreports.results && this.props.allreports.results.length > 0 && handleExcelData(this.props.fields, this.props.allreports.results);
                                    exportToCSV(excelExportData, 'E-Forms')
                                }}>Export</Button>
                            </Col>
                            <span></span>
                        </Row>
                        <Card className='mt-2'>
                            <Card.Body className='font-medium'>
                                <Form onSubmit={(e) => { this.handleSubmitAction(e) }}>
                                    <Row>
                                        <Col>
                                            <Form.Label>Select template <span className='text-danger'>*</span></Form.Label>
                                            <Form.Select size='sm' value={this.state.templateId} onChange={(event) => { this.setState({ templateId: event.target.value }) }} required>
                                                <option>-Select  Template-</option>
                                                {this.props.allFormList.data && this.props.allFormList.data.length > 0 && this.props.allFormList.data.map((row, index) => {
                                                    return (
                                                        <option value={row.id} key={index}>{row.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control size="sm" type="date" placeholder="Enter Description" value={this.state.startDate}
                                                onChange={event => { this.setState({ startDate: event.target.value }); }} />
                                        </Col>
                                        <Col>
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control size="sm" type="date" placeholder="Enter Description" value={this.state.endDate}
                                                onChange={event => { this.setState({ endDate: event.target.value }); }} />
                                        </Col>
                                        {
                                            this.props.templateUsers && this.props.templateUsers.length > 0 &&
                                            <Col>

                                                <Form.Label>Select User</Form.Label>
                                                <Form.Select size='sm' value={this.state.templateUsers} onChange={(event) => { this.setState({ user: event.target.value }) }}>
                                                    <option value={''}>-Select  User-</option>
                                                    {this.props.templateUsers && this.props.templateUsers.map((row, index) => {
                                                        return (
                                                            <option value={row.user.id} key={index}>{row.user.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Col>
                                        }
                                        <Col className='d-flex align-items-end'>
                                            <Button type='submit' variant="outline-success" size='sm'>SEARCH</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row className='mt-3' style={{ flexDirection: "row", flexWrap: "wrap" }}>
                                    {
                                        Object.keys(this.state.arrangedAutoSum).length > 0 && Object.keys(this.state.arrangedAutoSum).map((keys, index) => {
                                            return (
                                                <Col md="6">
                                                    <Row>
                                                        <Col md='4' style={{ fontWeight: "bold" }}>{keys}</Col>
                                                        <Col md='2'>:</Col>
                                                        <Col md='6'>{Object.values(this.state.arrangedAutoSum)[index]}</Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                <div className='mt-3 font-medium'>
                                    {
                                        this.props.allreports && this.props.allreports.results && this.props.allreports.results.length > 0 &&
                                        <DataTable
                                        multifilter={(quarydata)=>{
                                        this.fetchquarydata(quarydata)
                                        }}
                                        clearfilter={()=>{
                                            this.handleSubmitAction(this.state.event)
                                        }}
                                        filter={true}
                                            totalItemsCount={this.props.allreports.count}
                                            onPageChange={(pageNumber) => this.handleSubmitAction(this.state.event, pageNumber)}
                                            headers={getReportFieldLabelArrayTable(this.props.fields)}
                                            Id={getReportFieldIdArrayTable(this.props.fields)}
                                            body={
                                                this.props.allreports && this.props.allreports.results && this.props.allreports.results.length > 0 && this.props.allreports.results.map((row, index) => {
                                                    let data = arrangeFormDataReportTable(this.props.fields, row.data);
                                                    data.push(formateDate(row.date_updated))
                                                    data.push(row.user === null ? 'Anonymous' : row.user.name)
                                                    return data;
                                                })
                                            }
                                        />
                                    }
                                </div>
                                <DeleteModal modalText={"Delete Form ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        templateUsers: state.reportsReducer.templateUsers,
        fields: state.formsReducer.fields,
        formData: state.formsReducer.formData,
        formList: state.formsReducer.formList,
        allreports: state.reportsReducer.allreports,
        token: state.usersReducer.token,
        allFormList: state.formsReducer.allFormList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTemplateUser: (accessToken, templateId) => dispatch(fetchTemplateUser(accessToken, templateId)),
        getFormData: (accessToken, templateId) => dispatch(fetchFormData(accessToken, templateId)),
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        getFormList: (accessToken, searchQuery, pageNumber) => dispatch(fetchFormsList(accessToken, searchQuery, pageNumber)),
        getReportData: (accessToken, templateId, requestBody) => dispatch(fetchAllReports(accessToken, templateId, requestBody)),
        getReportDatafilter: (accessToken, templateId, requestBody,data) => dispatch(fetchAllReportsFilter(accessToken, templateId, requestBody,data)),
        fetchAllFormsList: (accesstoken) => dispatch(fetchAllFormsList(accesstoken)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllReports);
